.projectOpen-section {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 596px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 160px;
}
.projectOpen-section .section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: white;
  padding-top: 86px;
}
.projectOpen-section .section .title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 4px;
}
.projectOpen-section .section .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.projectOpen-section .section > button, .projectOpen-section .section > a {
  margin-top: 24px;
  color: black;
  padding: 16px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 320px;
  height: 50px;
  background-color: white;
}
.projectOpen-section .section > button > img, .projectOpen-section .section > a > img {
  width: 27px;
}
.projectOpen-section .section > button > .text, .projectOpen-section .section > a > .text {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.projectOpen-section .section .guid-btn {
  background-color: black;
  color: white;
}
.projectOpen-section .section .open-btn {
  color: black;
  background-color: white;
}
.projectOpen-section .section .lettering {
  position: absolute;
  bottom: 96Px;
}
.projectOpen-section .section .bulb {
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .projectOpen-section .section .title {
    width: 250px;
    font-size: 24px;
  }
  .projectOpen-section .section .text {
    font-size: 13px;
    line-height: 180%;
  }
  .projectOpen-section .section .bulb {
    position: absolute;
    bottom: 0;
    width: 280px;
    z-index: 1;
  }
}