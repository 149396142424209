#root .pqbox-detail-page {
  padding: 161px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  -ms-user-select: none;
      user-select: none;
}
#root .pqbox-detail-page .pqbox-detail-wrapper {
  width: 728px;
  margin: 0 auto;
}
#root .pqbox-detail-page .pqbox-detail-top {
  max-width: 1366px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 351px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 31px;
}
#root .pqbox-detail-page .pqbox-detail-top .pqbox-detail-wrapper .pqbox-detail-top-tag {
  font-size: 16px;
  font-weight: 700;
}
#root .pqbox-detail-page .pqbox-detail-top .pqbox-detail-wrapper .pqbox-detail-top-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 140%;
  margin: 8px 0 12px;
}
#root .pqbox-detail-page .pqbox-detail-top .pqbox-detail-wrapper .pqbox-detail-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2px;
     -moz-column-gap: 2px;
          column-gap: 2px;
}
#root .pqbox-detail-page .pqbox-detail-top .pqbox-detail-wrapper .pqbox-detail-author .profile-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#root .pqbox-detail-page .pqbox-detail-top .pqbox-detail-wrapper .pqbox-detail-author .profile-nickname {
  font-size: 12px;
  font-weight: 700;
}
#root .pqbox-detail-page .pqbox-detail-content {
  padding: 32px 0;
  width: 100%;
  border-bottom: solid 1px var(--grey200);
}
#root .pqbox-detail-page .pqbox-detail-bottom-period {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: var(--Gradation, linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%));
}
#root .pqbox-detail-page .pqbox-detail-bottom-period > .pqbox-detail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1365px) {
  #root .pqbox-detail-page .pqbox-detail-wrapper {
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}