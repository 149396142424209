#root .drop-down-comp {
  width: 109px;
  height: 32px;
  position: relative;
}
#root .drop-down-comp .drop-down-wrapper {
  border-radius: 5px;
  border: solid 1px var(--grey200);
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: white;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .drop-down-comp .drop-down-wrapper .now-drop-down > img {
  width: 20px;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
#root .drop-down-comp .drop-down-wrapper > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 32px;
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
}
#root .drop-down-comp .drop-down-open .now-drop-down > img {
  -webkit-transform: unset;
          transform: unset;
}