.policy-section {
  margin-top: 25px;
}
.policy-section .policy-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 16px;
}
.policy-section .policy-wrapper > span {
  color: red;
  font-size: 11px;
  padding-left: 4px;
}
.policy-section .policy-wrapper .left {
  padding-top: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.policy-section .policy-wrapper .left .purple {
  color: #9b1ce1;
  margin-right: 4px;
  padding-bottom: 2px;
}
.policy-section .policy-wrapper .left > .event {
  font-weight: bold;
  margin: 0 4px;
}
.policy-section .policy-wrapper .left > svg {
  margin-right: 8px;
}
.policy-section .policy-wrapper > a {
  font-size: 12px;
  color: #54585a;
  text-decoration: underline;
}
.policy-section .all {
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  height: 44px;
  background-color: #f5f6f8;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.policy-section .all > svg {
  margin-right: 8px;
}
.policy-section .projects-all {
  background-color: #f9f2fd;
}
.policy-section .ordermade-all {
  background-color: var(--red100);
}