#root .home-search-section {
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .home-search-section .search-title {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
  line-height: 140%;
}
#root .home-search-section .search-input-wrapper {
  -webkit-animation: inside 0.4s ease-in-out;
          animation: inside 0.4s ease-in-out;
  width: 557px;
  height: 48px;
  position: relative;
  border: 1px solid transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 100px;
  overflow: hidden;
}
#root .home-search-section .search-input-wrapper .search-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 8px 10px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .home-search-section .search-input-wrapper .search-input > input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .home-search-section .search-input-wrapper .search-input > button {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 767px) {
  #root .home-search-section .search-input-wrapper {
    width: 320px;
  }
}