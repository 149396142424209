#root .main-list-section {
  margin: 88px 0;
}
#root .main-list-section > .title-section {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .main-list-section > .title-section > b {
  font-size: 26px;
}
#root .main-list-section > .title-section > b > span {
  font-weight: 400;
}
#root .main-list-section > .title-section > a {
  font-size: 16px;
  color: var(--grey500);
}
#root .main-list-section .main-list-emty-wrapper {
  width: 100%;
  height: 309px;
}
#root .main-list-section .main-list-emty-wrapper > .emty-list > .emty-sub {
  white-space: nowrap;
}
#root .main-list-section > .list-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 23px;
     -moz-column-gap: 23px;
          column-gap: 23px;
}

@media screen and (max-width: 1365px) {
  #root .main-list-section {
    margin: 77px 0;
    width: 728px;
  }
  #root .main-list-section > .list-section {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 48px 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .main-list-section {
    width: 100%;
    margin: 56px 0;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .main-list-section > .title-section > b {
    font-size: 20px;
  }
  #root .main-list-section > .title-section > a {
    font-size: 13px;
  }
  #root .main-list-section > .list-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 16px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  #root .main-list-section .main-list-emty-wrapper {
    height: 196px;
  }
  #root .main-list-section .main-list-emty-wrapper > .emty-list > .emty-sub {
    white-space: pre-line;
    line-height: 150%;
  }
}