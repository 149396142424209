#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper {
  display: grid;
  row-gap: 16px;
  margin-top: 15px;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .set-period-box {
  width: 100%;
  background-color: var(--grey100);
  border-radius: 5px;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .set-period-box > .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .set-period-box > .sub {
  font-size: 14px;
  color: var(--grey500);
  font-weight: 400;
  line-height: 150%;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .set-period-box .set-period-input-wrapper {
  display: grid;
  grid-template-columns: 310px 16px 310px;
  margin-top: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .set-period-box .set-period-input-wrapper > .hip {
  font-size: 24px;
  font-weight: 400;
  padding-top: 10px;
}
#root .pqbox-period-page .pqbox-write-content-section .pqbox-period-wrapper .clear-period-box {
  background-color: var(--violet100);
}