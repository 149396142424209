#root .finish-page {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .finish-page .email-section .email-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .finish-page .email-section .email-logo > img {
  width: 94px;
  margin-bottom: 16px;
}
#root .finish-page .email-section .email-logo .email-title {
  text-align: center;
  font-size: 26px;
  padding-bottom: 16px;
  font-weight: 700;
}
#root .finish-page .email-section .email-logo .email-line {
  width: 320px;
  height: 1px;
  background-color: #dbdbdb;
  margin-bottom: 18px;
}
#root .finish-page .email-section .email-logo .email-id {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 40px;
}
#root .finish-page .email-section .email-logo .email-id > span {
  color: #9b1ce1;
}
#root .finish-page .email-section .email-logo .text {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #54585a;
  margin-bottom: 40px;
}
#root .finish-page .email-section .email-logo .nav-link {
  width: 320px;
  height: 50px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}