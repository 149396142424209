#root .comment-write-section {
  margin: 27px 0 42px;
}
#root .comment-write-section > .title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
#root .comment-write-section > .title > span {
  color: var(--main);
}
#root .comment-write-section > textarea {
  width: 100%;
  height: 96px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  padding: 11px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 16px 0;
}
#root .comment-write-section .comment-write-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .comment-write-section .comment-write-bottom .secret-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F5F6F8;
  padding: 12px 16px;
  height: 44px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  max-width: 320px;
  width: 80%;
}
#root .comment-write-section .comment-write-bottom .secret-btn > .circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  border: solid 1px var(--grey200);
}
#root .comment-write-section .comment-write-bottom .secret-btn > .secret-btn-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
#root .comment-write-section .comment-write-bottom .submit-btn {
  width: 62px;
  height: 42px;
  background-color: var(--grey600);
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}