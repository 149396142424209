#root .notice_detail {
  min-height: 100vh;
}
#root .notice_detail .wrapper {
  width: 1024px;
  position: relative;
  padding: 150px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
}
#root .notice_detail .wrapper .title {
  font-size: 25px;
  font-weight: bold;
}
#root .notice_detail .wrapper .head {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .notice_detail .wrapper .head img {
  width: 50px;
  height: 50px;
}
#root .notice_detail .wrapper .head .name_wrapper {
  margin-left: 7px;
}
#root .notice_detail .wrapper .head .name_wrapper .name {
  font-size: 17px;
  font-weight: bold;
}
#root .notice_detail .wrapper .head .name_wrapper .name_sub {
  font-size: 14px;
  font-weight: 500;
  color: #707070;
}
#root .notice_detail .wrapper .head .timestamp {
  font-size: 15px;
  color: #5d5d5d;
  position: absolute;
  right: 0;
  bottom: 0;
}
#root .notice_detail .wrapper .greybar {
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
  margin-top: 21.5px;
  margin-bottom: 46.5px;
}
#root .notice_detail .wrapper .body .img_wrapper {
  position: relative;
  margin-bottom: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .notice_detail .wrapper .body .notice_content {
  white-space: pre-line;
  font-size: 17px;
  color: #262626;
  line-height: 1.79;
  margin-bottom: 79px;
}

@media screen and (max-width: 1365px) {
  #root .notice_detail {
    width: 100%;
    padding: 0;
  }
  #root .notice_detail .wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 180px 20px;
  }
  #root .notice_detail .wrapper .title {
    font-size: 16px;
  }
  #root .notice_detail .wrapper .head {
    margin-top: 14px;
  }
  #root .notice_detail .wrapper .head img {
    width: 30px;
    height: 30px;
  }
  #root .notice_detail .wrapper .head .name_wrapper .name {
    font-size: 11px;
  }
  #root .notice_detail .wrapper .head .name_wrapper .name_sub {
    font-size: 10px;
  }
  #root .notice_detail .wrapper .head .timestamp {
    font-size: 11px;
  }
  #root .notice_detail .wrapper .greybar {
    margin-top: 13.5px;
    margin-bottom: 19.5px;
  }
  #root .notice_detail .wrapper .body {
    width: 100%;
  }
  #root .notice_detail .wrapper .body .img_wrapper {
    width: 100%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .notice_detail .wrapper .body .img_wrapper img {
    width: 100%;
    height: unset;
  }
  #root .notice_detail .wrapper .body .notice_content {
    width: 100%;
    font-size: 14px;
    word-break: break-all;
  }
}