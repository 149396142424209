#root .pqbox-user-card {
  width: 100%;
  height: 72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 19px;
  overflow: hidden;
  position: relative;
}
#root .pqbox-user-card > .card-left {
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-template-columns: 128px 232px 103px 125px 80px 48px;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  font-size: 13px;
  font-weight: 400;
}
#root .pqbox-user-card > .card-left > img {
  height: 100%;
}
#root .pqbox-user-card > .card-left > .title {
  font-weight: bold;
}
#root .pqbox-user-card > .card-left .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .pqbox-user-card > .card-left .category > img {
  width: 32px;
}
#root .pqbox-user-card > .card-left .pqbox-card-state {
  text-align: center;
  font-weight: bold;
}
#root .pqbox-user-card > .card-left .reject-card {
  color: var(--red600);
}
#root .pqbox-user-card > .card-left .funding-card {
  color: var(--amin);
}
#root .pqbox-user-card > .card-left .finish-card,
#root .pqbox-user-card > .card-left .blind-card {
  color: var(--grey400);
}
#root .pqbox-user-card > .right-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .pqbox-user-card > .right-btn-wrapper > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 12px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  background: var(--grey100);
  font-size: 13px;
  font-weight: 700;
  color: var(--grey500);
}
#root .pqbox-user-card > .right-btn-wrapper > button:disabled {
  color: var(--grey300);
}

@media screen and (max-width: 1365px) {
  #root .pqbox-user-card {
    height: 370px;
    padding: 195px 15px 14px 15px;
  }
  #root .pqbox-user-card > .card-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  #root .pqbox-user-card > .card-left > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  #root .pqbox-user-card > .card-left > .title {
    margin: 1px 0 10px;
    height: 45px;
  }
  #root .pqbox-user-card > .card-left .category {
    margin-top: 12px;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  #root .pqbox-user-card > .card-left .category > svg {
    width: 42px;
  }
  #root .pqbox-user-card > .right-btn-wrapper {
    position: absolute;
    bottom: 14px;
    right: 17px;
  }
}