#root .footer-section {
  width: 100%;
  background-color: #535353;
  padding: 58px 0 67px;
  color: var(--white);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .footer-section .footer-content-wrapper {
  width: 1024px;
  margin: 0 auto;
}
#root .footer-section .footer-content-wrapper .footer-title-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .footer-section .footer-content-wrapper .footer-title-section .footer-title {
  font-size: 18px;
}
#root .footer-section .footer-content-wrapper .footer-sns-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 23px;
     -moz-column-gap: 23px;
          column-gap: 23px;
}
#root .footer-section .footer-content-wrapper .footer-menu-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: solid 1px var(--white);
  border-bottom: solid 1px var(--white);
  padding: 10px 0;
  -webkit-column-gap: 28px;
     -moz-column-gap: 28px;
          column-gap: 28px;
  margin: 15px 0 27px 0;
}
#root .footer-section .footer-content-wrapper .footer-menu-section > a {
  color: var(--white);
  font-size: 13px;
  white-space: nowrap;
}
#root .footer-section .footer-content-wrapper .footer-content-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 50px;
     -moz-column-gap: 50px;
          column-gap: 50px;
  font-size: 13px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-logo > img {
  width: 246px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-logo > .copyright {
  margin-top: 158px;
  font-size: 13px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-content {
  line-height: 20px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-content .footer-list {
  margin: 16px 0;
  display: grid;
  row-gap: 7px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-content .footer-list > .list-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .footer-section .footer-content-wrapper .footer-content-section .footer-content .footer-list > .list-line > img {
  width: 16px;
}

@media screen and (max-width: 1365px) {
  #root .footer-section {
    padding: 31px 20px 67px 20px;
  }
  #root .footer-section .footer-content-wrapper {
    width: 100%;
  }
  #root .footer-section .footer-content-wrapper .footer-menu-section {
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
  #root .footer-section .footer-content-wrapper .footer-content-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 16px;
  }
  #root .footer-section .footer-content-wrapper .copyright {
    margin-top: 16px;
  }
  #root .footer-section .footer-content-wrapper .footer-sns-wrapper {
    margin-top: 40px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  #root .footer-section .footer-content-wrapper .footer-content-section .footer-content .footer-sub > .mb-br {
    display: none;
  }
}