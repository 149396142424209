.banner-section {
  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
}
.banner-section > .wrapper {
  overflow: hidden;
  position: relative;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 717px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
  width: 100%;
  height: 402px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider {
  display: grid;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider > .back-img > img {
  width: 717px;
  height: 405px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .index-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 700;
  font-size: 12px;
  width: 50px;
  height: 26px;
  border-radius: 30px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .btn-wrapper > .pagination-basic {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .btn-wrapper > .pagination {
  background-color: white;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .btn-wrapper > svg {
  width: 23px;
  cursor: pointer;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper > .btn-wrapper > .right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
  width: 100%;
  padding-top: 30px;
  overflow: hidden;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider {
  display: grid;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content {
  color: black;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .category {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  background-color: var(--main);
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .category-sk {
  width: 60px;
  height: 22px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
  font-weight: 700;
  font-size: 34px;
  white-space: pre-wrap;
  margin: 8px 0;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-wrap;
}

.Black {
  background-color: black;
  color: white;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a {
  background-color: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover {
  background-color: white;
  color: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .left {
  stroke: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .right {
  fill: #0062FF;
}

.Blue {
  background-color: #0062FF;
  color: white;
}

.Skyblue {
  background-color: #7BB1FF;
}

.Green {
  background-color: #5DC287;
}

.Purple {
  background-color: #8741EA;
  color: white;
}

@media screen and (max-width: 1365px) {
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
    width: 728px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    width: 728px;
    height: 408px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper .img-slider .back-img > img {
    width: 728px;
    height: 408px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
    font-size: 26px;
    line-height: 35px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 18px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a {
    width: 156px;
  }
  .banner-section > .wrapper > .slide-wrapper {
    bottom: 53px;
  }
}
@media screen and (max-width: 767px) {
  .banner-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .banner-section > .wrapper {
    width: 100%;
    -webkit-box-sizing: unset;
            box-sizing: unset;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    row-gap: 44px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    width: 100%;
    height: 55vw;
    border-radius: unset;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider {
    width: 100%;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0;
    padding: unset;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider .back-img > img {
    width: 100%;
    height: 55vw;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
    width: 100vw;
    padding: unset;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content {
    width: 100%;
    padding: 0 20px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
    font-size: 24px;
    margin: 3px 0;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
    font-size: 14px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 11px;
       -moz-column-gap: 11px;
            column-gap: 11px;
    padding-left: 20px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .index-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: var(--grey100);
    color: var(--grey400);
    font-weight: 700;
    font-size: 12px;
    width: 50px;
    height: 26px;
    border-radius: 30px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 4px;
       -moz-column-gap: 4px;
            column-gap: 4px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .btn-wrapper > svg {
    width: 23px;
    cursor: pointer;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .btn-wrapper > svg > circle {
    fill: var(--grey100);
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .btn-wrapper > svg > path {
    stroke: var(--grey400);
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .slide-wrapper > .btn-wrapper > .right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}