#root .emty-list {
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: var(--grey400);
  background-color: #F5F6F8;
  width: 100%;
  height: 100%;
}
#root .emty-list > img {
  width: 100px;
}
#root .emty-list > .emty-title {
  font-size: 16px;
  font-weight: 700;
}
#root .emty-list > .emty-sub {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  white-space: pre-line;
}

@media screen and (max-width: 767px) {
  #root .emty-list {
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}