#root .story-like-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
  font-size: 13px;
  font-weight: 700;
  color: var(--grey400);
}
#root .story-like-btn .like-on {
  fill: var(--red600);
}
#root .story-like-btn .like-on > g > path {
  stroke: none;
}
#root .detail-like-btn {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}