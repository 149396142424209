#root .notice-card {
  border-top: 1px solid #dbdbdb;
  padding: 16px 0px;
  cursor: pointer;
  width: 100%;
  height: 87px;
  position: relative;
}
#root .notice-card .card .top .title {
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 15px;
}
#root .notice-card .card .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .notice-card .card .profile .date {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: #949494;
}
#root .sk-notice .card > .profile > .date {
  width: 120px;
}

@media screen and (max-width: 767px) {
  #root .notice-card .card .top .title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 15px;
    text-align: left;
  }
  #root .notice-card .card .profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  #root .notice-card .card .profile .date {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    color: #949494;
  }
}