@charset "UTF-8";
#root .story-write-page {
  padding: 200px 0;
  width: 1024px;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .story-write-page .page-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}
#root .story-write-page .sub-title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}
#root .story-write-page .category-container {
  margin-top: 25px;
}
#root .story-write-page .form-container {
  width: 100%;
  margin-top: 24px;
}
#root .story-write-page .form-container .submit-button {
  width: 174px;
  height: 42px;
  border: none;
  border-radius: 7px;
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
#root .story-write-page .form-container .disabled-button {
  background-color: rgba(204, 204, 204, 0.8);
}
#root .story-write-page .form-container .input-container {
  margin-bottom: 24px;
}
#root .story-write-page .form-container .input-container .title-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--grey200);
  padding: 11px 0px 10px 16px;
}
#root .story-write-page .form-container .input-container .description-input {
  width: 100%;
  height: 260px;
  border: 1px solid var(--grey200);
  border-radius: 5px;
  padding: 11px 0px 0px 16px;
}
#root .story-write-page .form-container .image-container .explanation {
  margin: 4px 0px 12px 0px;
  line-height: 16.5px;
}
#root .story-write-page .form-container .image-container .explanation span {
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: var(--grey500);
}
#root .story-write-page .form-container .image-container .image-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
#root .story-write-page .form-container .image-container .image-upload .wide-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  #root .story-write-page {
    width: 100%;
    padding: 200px 24px;
  }
}
@media screen and (max-width: 767px) {
  #root .story-write-page {
    width: 100%;
    padding: 100px 22px 70px 18px;
  }
  #root .story-write-page .page-title {
    padding: 0 20px;
  }
  #root .story-write-page .image-upload::-webkit-scrollbar {
    display: none;
  }
  #root .story-write-page .image-upload {
    width: 100vw;
    overflow-x: auto;
    /* 인터넷 익스플로러 */
    -ms-overflow-style: none;
  }
  #root .story-write-page .image-upload .wide-container {
    width: 10000000px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .story-write-page .form-container .submit-button {
    width: 100%;
  }
}