.line-add {
  width: 100%;
  height: 190px;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.line-add > .wrapper {
  width: 1024px;
  height: 190px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.line-add > .wrapper > .banner-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.line-add > .wrapper > .banner-wrapper > .line-banner {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.line-add > .wrapper > .banner-wrapper > .line-banner > .left > .title {
  color: white;
  font-weight: 700;
  font-size: 26px;
  white-space: pre-line;
}
.line-add > .wrapper > .banner-wrapper > .line-banner > .left > .sub {
  color: white;
  font-weight: 400;
  font-size: 16px;
  white-space: pre-line;
  margin: 13px 0 24px;
}
.line-add > .wrapper > .banner-wrapper > .line-banner > .left > a {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 137px;
  height: 32px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  background-color: white;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.line-add > .wrapper > .banner-wrapper > .line-banner > .left > a:hover {
  background-color: black;
  color: white;
}
.line-add > .wrapper > .banner-wrapper > .line-banner > img {
  width: 160px;
}
.line-add > .wrapper > .index-wrapper {
  position: absolute;
  bottom: 16.47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 9.2px;
     -moz-column-gap: 9.2px;
          column-gap: 9.2px;
}
.line-add > .wrapper > .index-wrapper > .circle {
  width: 7.53px;
  height: 7.53px;
  border-radius: 7.5px;
  background-color: white;
  opacity: 0.3;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.line-add > .wrapper > .index-wrapper > .cl-active {
  opacity: 1;
}

.puple {
  background-color: #9b1ce1;
}

.aqua {
  background-color: aqua;
}

.black {
  background-color: black;
}

@media screen and (max-width: 1439px) and (min-width: 768px) {
  .line-add .wrapper {
    width: 728px;
  }
}
@media screen and (max-width: 767px) {
  .line-add > .wrapper {
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .line-add > .wrapper > .banner-wrapper > .line-banner > .left > .title {
    font-size: 18px;
    line-height: 24px;
  }
  .line-add > .wrapper > .banner-wrapper > .line-banner > .left > .sub {
    font-size: 11px;
  }
  .line-add > .wrapper > .banner-wrapper > .line-banner > img {
    width: 120px;
  }
  .line-add > .wrapper > .index-wrapper {
    bottom: 11.47px;
  }
}