#root .common-input {
  position: relative;
}
#root .common-input .common-input-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
#root .common-input .common-input-title > span {
  font-weight: 700;
  color: var(--grey500);
}
#root .common-input .common-input-title > .clear-text {
  font-weight: 400;
  color: var(--main);
}
#root .common-input .common-input-title > .error {
  font-weight: 400;
  color: var(--red600);
}
#root .common-input .common-file-label {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 0 16px;
  background-color: var(--grey100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .common-input .common-file-label > .title {
  font-size: 16px;
  color: var(--grey500);
}
#root .common-input .common-file-label > img {
  width: 24px;
}
#root .common-input > .file-insert {
  background-color: var(--violet100);
}
#root .common-input > .file-insert > .title {
  color: var(--main);
}
#root .common-input .remove-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 10;
}
#root .common-input > input {
  width: 100%;
  height: 50px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  padding: 0 16px;
}
#root .input-error > input {
  border-color: var(--red600);
}
#root .input-error .common-input-title > .clear-text {
  color: var(--red600);
}
#root .input-error > .common-file-label {
  border: solid 1px var(--red600);
}