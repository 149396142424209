#root .categoryfilter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
#root .categoryfilter > button {
  border: 1px solid #949494;
  border-radius: 19px;
}
#root .categoryfilter > button > p {
  padding: 8px 16px;
  color: #949494;
  font-weight: bold;
  white-space: nowrap;
}
#root .categoryfilter > .select-category-btn {
  position: relative;
  border: 1px solid transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
#root .categoryfilter > .select-category-btn > p {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#root .categoryfilter::-webkit-scrollbar {
  display: none;
}