@-webkit-keyframes box-open {
  from {
    height: 0px;
  }
  to {
    height: 292px;
  }
}
@keyframes box-open {
  from {
    height: 0px;
  }
  to {
    height: 292px;
  }
}
[contenteditable] {
  outline: 0px solid transparent;
}

#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}
#root .editor {
  margin-top: 20px;
  background-color: rgb(249, 249, 249);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .editor .editor-wrapper {
  border-radius: 10px;
  border: solid 1px #dbdbdb;
  width: 1024px;
  position: relative;
  min-height: 1440px;
  background-color: white;
  padding: 20px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .editor .editor-wrapper .edit-header {
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: solid 1px #dbdbdb;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  z-index: 200;
}
#root .editor .editor-wrapper .edit-header button {
  outline: 0;
  border: 0;
  background-color: white;
}
#root .editor .editor-wrapper .edit-header .line {
  height: 29px;
  width: 1px;
  background-color: #dbdbdb;
}
#root .editor .editor-wrapper .edit-header .ftsize {
  position: relative;
  font-size: 15px;
}
#root .editor .editor-wrapper .edit-header .ftsize .now-ft {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 85px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
#root .editor .editor-wrapper .edit-header .ftsize .now-ft > img {
  width: 10.5px;
  height: 6px;
  margin-left: 7px;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
#root .editor .editor-wrapper .edit-header .ftsize .ft-list {
  -webkit-animation: box-open 0.5s;
          animation: box-open 0.5s;
  top: 32px;
  width: 85px;
  height: 292px;
  position: absolute;
  display: grid;
  row-gap: 17px;
  padding-top: 15px;
  padding-bottom: 23px;
  border: solid 1px #dbdbdb;
  z-index: 500;
  overflow: hidden;
  background-color: white;
}
#root .editor .editor-wrapper .edit-header .ftsize .ft-list .ft-card {
  padding-left: 15.5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}
#root .editor .editor-wrapper .edit-header .ftsize .ft-list .ft-card .sky-circle {
  background-color: var(--main);
  margin-left: 18.5px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper {
  margin-right: 12.5px;
  position: relative;
  margin-left: 10.5px;
  display: grid;
  grid-template-columns: repeat(3, 29px);
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper .style-btn {
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 29px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper .style-btn > img {
  width: 29px;
  height: 29px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper .style-btn .color-box {
  bottom: 1px;
  right: 3px;
  position: absolute;
  width: 6px;
  height: 6px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper .color-wrapper {
  padding: 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: -71px;
  right: -135px;
  width: 162px;
  height: 61px;
  background-color: white;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  position: absolute;
  border: solid 1px #dbdbdb;
  display: grid;
  grid-template-columns: repeat(6, 22px);
  row-gap: 3px;
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
}
#root .editor .editor-wrapper .edit-header .ftstyle-wrapper .color-wrapper .color-box {
  cursor: pointer;
  height: 22px;
  border-radius: 2px;
}
#root .editor .editor-wrapper .edit-header .ftalign-wrapper {
  margin-left: 15.5px;
  margin-right: 11.5px;
  display: grid;
  grid-template-columns: repeat(3, 29px);
  -webkit-column-gap: 13px;
     -moz-column-gap: 13px;
          column-gap: 13px;
}
#root .editor .editor-wrapper .edit-header .ftalign-wrapper .align-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
#root .editor .editor-wrapper .edit-header .ftalign-wrapper .align-btn > img {
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  width: 29px;
  height: 29px;
}
#root .editor .editor-wrapper .edit-header .insert-wrapper {
  margin-left: 26.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
#root .editor .editor-wrapper .edit-header .insert-wrapper .test-img {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
#root .editor .editor-wrapper .edit-header .insert-wrapper .test-img > input {
  position: absolute;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 28px;
     -moz-column-gap: 28px;
          column-gap: 28px;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > button {
  cursor: pointer;
  width: 110px;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > button:hover {
  opacity: 0.8;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > .save {
  background-color: black;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > .look {
  color: black;
  background-color: #D1DEFF;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > .init {
  background-color: black;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > .back {
  background-color: #DBDBDB;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 14px;
  -webkit-column-gap: 2px;
     -moz-column-gap: 2px;
          column-gap: 2px;
}
#root .editor .editor-wrapper .edit-header > .btn-wrapper > div > .back > img {
  width: 17px;
}
#root .editor .editor-wrapper .editor-screen {
  font-size: 15px;
  text-align: left;
  min-height: 1500px;
  border: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .editor .editor-wrapper .editor-screen div,
#root .editor .editor-wrapper .editor-screen span,
#root .editor .editor-wrapper .editor-screen p,
#root .editor .editor-wrapper .editor-screen font {
  width: 100% !important;
  min-height: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 5px;
  word-break: break-all;
  word-wrap: break-word;
  margin: unset !important;
  text-indent: unset !important;
  line-height: unset !important;
}
#root .editor .editor-wrapper .editor-screen #text-line {
  width: 100%;
  min-height: 30px;
}
#root .editor .editor-wrapper .editor-screen .delete-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: -34px;
  right: 0;
}
#root .editor .editor-wrapper .editor-screen .focus {
  border: solid 1px var(--main);
}
#root .editor .editor-wrapper .editor-screen .template-youtube {
  position: relative;
  width: 100%;
  height: 660px;
}
#root .editor .editor-wrapper .editor-screen .lite::after {
  cursor: -webkit-grab;
  cursor: grab;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#root .editor .editor-wrapper .editor-screen .link-template,
#root .editor .editor-wrapper .editor-screen .file-template {
  background-color: white;
  margin: 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  border: solid 1px var(--grey500);
  height: 53px;
  padding-left: 15.4px;
  padding-right: 21.3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
#root .editor .editor-wrapper .editor-screen .link-template > .file-asset,
#root .editor .editor-wrapper .editor-screen .file-template > .file-asset {
  width: 21px;
  margin-right: 15.5px;
}
#root .editor .editor-wrapper .editor-screen .link-template > .link-asset,
#root .editor .editor-wrapper .editor-screen .file-template > .link-asset {
  margin-right: 8.4px;
  width: 34.2px;
}
#root .editor .editor-wrapper .editor-screen .link-template > .link-title,
#root .editor .editor-wrapper .editor-screen .file-template > .link-title {
  font-size: 17px;
  font-weight: bold;
  color: var(--grey500);
  text-align: left;
}
#root .editor .editor-wrapper .editor-screen .link-template > .link-play,
#root .editor .editor-wrapper .editor-screen .file-template > .link-play {
  position: absolute;
  right: 21.3px;
  width: 9.6px;
}
#root .editor .editor-wrapper .editor-screen .news-template {
  width: 100%;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
#root .editor .editor-wrapper .editor-screen .news-template .news-image-wrapper {
  height: 512px;
  border-radius: 10px;
  overflow: hidden;
  /* Firefox */
}
#root .editor .editor-wrapper .editor-screen .news-template .news-image-wrapper .news-image-section {
  display: grid;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .editor .editor-wrapper .editor-screen .news-template .news-image-wrapper .news-image-section > img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .editor .editor-wrapper .editor-screen .news-template .news-page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 14px;
}
#root .editor .editor-wrapper .editor-screen .news-template .news-page-wrapper > div {
  width: 8px !important;
  height: 8px;
  min-height: unset;
  border-radius: 50%;
  background-color: var(--grey500);
  padding-bottom: unset;
  opacity: 0.2;
}
#root .editor .editor-wrapper .editor-screen .news-template::-webkit-scrollbar {
  display: none;
}
#root .editor .editor-wrapper .editor-screen .active-blue {
  border: solid 2px var(--grey500);
  width: calc(100% - 2px);
}
#root .editor .editor-wrapper .editor-screen b {
  font-weight: bold;
}
#root .editor .popup-pack {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
}
#root .editor .popup-pack .back-black {
  z-index: 500;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
}
#root .editor .popup-pack .popup-main {
  z-index: 600;
  width: 948px;
  background-color: white;
  border-radius: 5px;
  position: fixed;
}
#root .editor .popup-pack .popup-main .popup-wrapper {
  height: 358px;
  padding: 25px 27px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .editor .popup-pack .popup-main .popup-wrapper > .cancel {
  top: 25px;
  right: 27px;
  width: 24px;
  position: absolute;
  cursor: pointer;
}
#root .editor .popup-pack .popup-main .popup-wrapper .popup-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 25px;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .editor .popup-pack .popup-main .popup-wrapper .popup-sub {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .news-popup-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
  margin: 25px 0;
}
#root .editor .popup-pack .popup-main .popup-wrapper .content-wrapper {
  margin-top: 35px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .content-wrapper .content-title {
  font-size: 13px;
  font-weight: bold;
  color: #898989;
}
#root .editor .popup-pack .popup-main .popup-wrapper .content-wrapper .second {
  margin-top: 15px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .content-wrapper .content-input {
  padding: 0px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 43px;
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  margin-top: 9px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .file-content {
  margin-top: 28px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .file-content .file-insert {
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 149px;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
}
#root .editor .popup-pack .popup-main .popup-wrapper .file-content .file-insert > img {
  width: 47.1px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .file-content .file-insert > .insert-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #c3c3c3;
}
#root .editor .popup-pack .popup-main .popup-wrapper .link-btn {
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  width: 110px;
  height: 44px;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  border-radius: 5px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: right;
  position: absolute;
  right: 27px;
  font-size: 14px;
  font-weight: bold;
  bottom: 35px;
  cursor: pointer;
}
#root .editor .popup-pack .popup-main .popup-wrapper .file-btn {
  bottom: 30px;
}
#root .editor .popup-pack .popup-main .popup-wrapper > .list-wrapper {
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  height: 128px;
  border: 1px solid #dbdbdb;
  background-color: rgb(249, 249, 249);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  position: relative;
}
#root .editor .popup-pack .popup-main .popup-wrapper > .list-wrapper > input {
  position: absolute;
}
#root .editor .popup-pack .popup-main .popup-wrapper > .list-wrapper .video-icon {
  width: 36px;
}
#root .editor .popup-pack .popup-main .popup-wrapper > .list-wrapper .video-file-title {
  margin-top: 6.3px;
  font-size: 14px;
  font-weight: bold;
  color: #c3c3c3;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper {
  margin-top: 18px;
  display: grid;
  row-gap: 10px;
  max-height: 230px;
  overflow-y: scroll;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card {
  width: 100%;
  height: 69px;
  position: relative;
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px 17px 12px 10px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .video-cancel {
  position: absolute;
  right: 17px;
  width: 14px;
  cursor: pointer;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .thumbnail-wrapper {
  width: 83px;
  height: 46px;
  margin-right: 13.5px;
  background-color: #dbdbdb;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .thumbnail-wrapper .video-url {
  width: 100%;
  height: 100%;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .card-progress {
  position: relative;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .card-progress .percent {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: bold;
  color: var(--main);
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .card-progress .progress-wrapper {
  border-radius: 8px;
  width: 396px;
  height: 5px;
  background-color: #dbdbdb;
  overflow: hidden;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .card-progress .progress-wrapper .progress-bar {
  width: 100%;
  height: 100%;
  background-color: var(--main);
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .card-progress .video-title {
  width: 214px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 7.5px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
#root .editor .popup-pack .popup-main .popup-wrapper .video-card-wrapper .video-card .check_circle {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #dbdbdb;
  margin-left: 18.3px;
  cursor: pointer;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-link {
  margin-top: 34px;
  width: 100%;
  height: 43px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-sub {
  width: 100%;
  height: 130px;
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  margin-top: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-sub .sub-left {
  margin-right: 57px;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-sub .sub-left > .left-title {
  font-size: 13px;
  font-weight: bold;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-sub .sub-left > .left-sub {
  margin-top: 4px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.8;
}
#root .editor .popup-pack .popup-main .popup-wrapper .youtube-sub .sub-right {
  width: 502px;
}
#root .editor .popup-pack .popup-main .card-popup {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .editor .popup-pack .popup-main .card-popup .news-upload-btn {
  width: 256px;
  height: 50px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
}
#root .editor .popup-pack .popup-main .video {
  height: 100%;
  padding-bottom: 100px;
}
#root .editor .popup-pack .popup-main .file {
  height: 340px;
}