#root .home-page {
  padding-top: 60px;
}
#root .home-page .home-wrapper {
  margin: 0 auto;
  width: 1024px;
}
#root .home-page .home-wrapper .main-list-section .story-list-section {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  #root .home-page {
    padding-top: 110px;
  }
  #root .home-page .home-wrapper {
    width: 728px;
  }
  #root .home-page .home-wrapper .main-list-section .story-list-section {
    display: grid;
    grid-template-columns: repeat(2, 356px);
    gap: 48px 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .home-page {
    padding-top: 110px;
  }
  #root .home-page .home-wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 20px;
  }
  #root .home-page .home-wrapper .main-list-section {
    padding: unset;
  }
  #root .home-page .home-wrapper .main-list-section .story-list-section {
    grid-template-columns: 100%;
    gap: 48px 16px;
  }
}