#root .signup-page {
  padding: 120px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .signup-page .signup-content-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 21px;
}
#root .signup-page .signup-content {
  width: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .signup-page .policy-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 16px;
}
#root .signup-page .policy-wrapper .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .signup-page .policy-wrapper .left .purple {
  color: #9b1ce1;
  margin-right: 4px;
  padding-bottom: 2px;
}
#root .signup-page .policy-wrapper .left > .event {
  font-weight: bold;
  margin: 0 4px;
}
#root .signup-page .policy-wrapper .left > img {
  margin-right: 8px;
  width: 20px;
}
#root .signup-page .policy-wrapper > a {
  font-size: 13px;
  color: #54585a;
  text-decoration: underline;
}
#root .signup-page .all {
  height: 44px;
  background-color: #f5f6f8;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
#root .signup-page .all-purple {
  background-color: #f9f2fd;
}
#root .signup-page .bottom {
  margin-top: 24px;
}
#root .signup-page .bottom .nextBtn {
  width: 100%;
  height: 50px;
  background-color: #dbdbdb;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
#root .signup-page .bottom .active {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}

@media screen and (max-width: 1365px) {
  #root .signup-page {
    padding: 140px 0;
  }
}
@media screen and (max-width: 767px) {
  #root .signup-page {
    padding: 120px 0 50px;
    -webkit-box-sizing: unset;
            box-sizing: unset;
  }
  #root .signup-page .signup-content-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  #root .signup-page .signup-content {
    max-width: 500px;
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    row-gap: 15px;
  }
  #root .signup-page .signup-content .policy-wrapper {
    padding: 0 10px;
  }
  #root .signup-page .signup-content .policy-wrapper .left {
    font-size: 12px;
  }
  #root .signup-page .signup-content .policy-wrapper .left > img {
    width: 18px;
  }
  #root .signup-page .signup-content .policy-wrapper .left > .event {
    margin: 0;
  }
  #root .signup-page .signup-content .policy-wrapper > a {
    font-size: 12px;
  }
}