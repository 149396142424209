#root .pqboxbasic-info-section {
  margin-top: 24px;
  padding-top: 8px;
  border-top: solid 1px var(--grey200);
}
#root .pqboxbasic-info-section > .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .pqboxbasic-info-section > .sub {
  font-size: 14px;
  color: var(--grey500);
  font-weight: 400;
  margin-top: 4px;
}
#root .pqboxbasic-info-section .info-section-content {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 37px;
     -moz-column-gap: 37px;
          column-gap: 37px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-left {
  width: 660px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 39px;
}
#root .pqboxbasic-info-section .info-section-content > .image-left {
  row-gap: 16px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right {
  width: 326px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .project-write-tag {
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 700;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-image-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 183px;
  background-color: var(--grey100);
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-image-section > img {
  width: 44px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-image-section > .title {
  font-size: 16px;
  color: var(--grey300);
  font-weight: 700;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-sub-image {
  display: grid;
  grid-template-columns: repeat(6, 70.4px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  margin-top: 8.5px;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-sub-image > .sub-image-box {
  cursor: pointer;
  width: 70.4px;
  height: 39px;
  background-color: var(--grey100);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .look-sub-image::-webkit-scrollbar {
  display: none;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .thumbnail-look > .category {
  font-size: 13px;
  font-weight: 700;
  color: var(--grey400);
  margin-top: 12px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .thumbnail-look > .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  margin: 6px 0 16px;
}
#root .pqboxbasic-info-section .info-section-content .info-section-right .thumbnail-look > .period {
  color: var(--main);
  font-size: 18px;
  font-weight: 700;
}