#root .search-page {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  padding: 138px 0;
}
#root .search-page .search-emty {
  width: 100%;
  height: 200px;
}
#root .search-page .search-section,
#root .search-page .pq-section-wrapper {
  width: 1024px;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 90px;
}
#root .search-page .button-container {
  text-align: center;
  margin-top: 49px;
}
#root .search-page .button-container .view-button {
  width: 112px;
  height: 37px;
  background-color: #f5f6f8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin: 0 auto;
}
#root .search-page .search-section {
  height: 100%;
}
#root .search-page .search-section .category-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .search-page .search-section .category-container > .project-list-top {
  padding-top: 0px;
}
#root .search-page .search-section > .ordermade-section {
  height: 100%;
}
#root .search-page .search-section > .ordermade-section .project-page {
  margin-bottom: 100px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 326px);
  gap: 44.5px 22px;
  margin-bottom: 0px;
}
#root .search-page .search-section .search-wrapper > .search-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
  margin-bottom: 16px;
}
#root .search-page .search-section .search-wrapper > .search-title-container > .title-text {
  font-size: 30px;
  font-weight: 700;
}
#root .search-page .search-section .search-wrapper > .search-title-container > .search-title {
  color: #949494;
}
#root .search-page .search-section .search-wrapper > .search-container {
  margin-bottom: 45px;
  position: relative;
  width: 419px;
  height: 48px;
  border-radius: 100px;
  border: 1px solid transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}
#root .search-page .search-section .search-wrapper > .search-container > input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 10px;
}
#root .search-page .search-section .search-wrapper > .search-container > .search-button {
  height: 33px;
  padding-right: 6px;
}
#root .search-page > .pq-section-wrapper {
  padding-top: 80px;
  width: 1024px;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 90px;
}
#root .search-page > .pq-section-wrapper .pq-section .card-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
#root .search-page > .pq-section-wrapper .pq-section .story-list-wrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 326px);
  gap: 40px 32px;
  margin-bottom: 15px;
}
#root .search-page .line {
  width: 100%;
  height: 8px;
  background-color: #F5F6F8;
}

@media screen and (max-width: 1365px) {
  #root .search-page {
    margin: 0 auto;
    padding: 180px 0;
  }
  #root .search-page .search-section {
    width: 728px;
  }
  #root .search-page .search-section .category-container {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .search-page .search-section .category-container .project-list-top .filter-btn-section {
    width: 100px;
  }
  #root .search-page .search-section .category-container .project-list-top .filter-button {
    width: 100px;
  }
  #root .search-page .search-section > .ordermade-section > .project-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  #root .search-page > .pq-section-wrapper {
    width: 728px;
  }
  #root .search-page > .pq-section-wrapper > .pq-section > .story-list-wrapper {
    grid-template-columns: repeat(2, 326px);
  }
}
@media screen and (max-width: 767px) {
  #root .search-page {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .search-page .search-emty {
    margin: 0 auto;
    width: 320px;
  }
  #root .search-page .search-section {
    width: 100%;
  }
  #root .search-page .search-section .search-wrapper {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .search-page .search-section .search-wrapper .search-container {
    width: 320px;
  }
  #root .search-page .search-section .category-container {
    width: 320px;
    margin: 0 auto;
    display: block;
  }
  #root .search-page .search-section .category-container .categoryfilter {
    row-gap: 12px;
  }
  #root .search-page .search-section .category-container .project-list-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 12px;
  }
  #root .search-page .search-section .category-container .project-list-top .project-top-wrapper {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    width: 100%;
  }
  #root .search-page .search-section .category-container .project-list-top .project-top-wrapper .project-list-top-right {
    width: 100%;
  }
  #root .search-page .search-section .category-container .project-list-top .project-top-wrapper .project-list-top-right .pq-box {
    width: 100%;
  }
  #root .search-page .search-section .category-container .project-list-top .filter-btn-wrapper .filter-button {
    width: 100%;
  }
  #root .search-page .search-section .ordermade-section .project-page {
    display: grid;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-template-columns: repeat(1, 326px);
  }
  #root .search-page .search-section .ordermade-section .project-page .project-card-wrapper .projects-project-card .thumbnail .thumbnail-img,
  #root .search-page .search-section .ordermade-section .project-page .project-card-wrapper .ordermade-project-card .thumbnail .thumbnail-img {
    width: 320px;
    height: 183px;
  }
  #root .search-page .pq-section-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .search-page .pq-section-wrapper .pq-section .story-list-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  #root .search-page .pq-section-wrapper .pq-section .story-list-wrapper .story-card {
    width: 320px;
    margin: 0 auto;
  }
  #root .search-page .pq-section-wrapper .pq-section .story-list-wrapper .story-card .story-card-top .story-card-img {
    height: 320px;
  }
}