#root .pay-card-box {
  text-align: left;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 17px 17px;
  border-radius: 5px;
  height: 90px;
  border: 1px solid #dbdbdb;
}
#root .pay-card-box > .bank {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 14px;
}
#root .pay-card-box > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .pay-card-box > .wrapper > .number {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #949494;
}
#root .pay-card-box > .wrapper > .check {
  position: absolute;
  top: 35px;
  right: 16px;
  width: 20px;
  height: 20px;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
}
#root .pay-card-box > .wrapper .check-hover {
  border: unset;
}
#root .pay-card-on {
  border: unset;
  background-color: #7f1ce1;
  color: white;
}
#root .pay-card-on > .wrapper .number {
  color: white;
}
#root .pay-card-on > .wrapper > .check {
  border: unset;
}