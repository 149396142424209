@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
.popup > .back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: unset;
          animation: unset;
}
.popup > .popup-content {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.popup > .popup-content > .cancel {
  position: absolute;
  cursor: pointer;
  width: 28px;
  right: 10px;
  top: 10px;
  z-index: 2;
}
.popup > .popup-content .save-popup {
  padding: 42px 32px 27px 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}
.popup > .popup-content .save-popup > .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}
.popup > .popup-content .save-popup > .sub {
  font-size: 13px;
  color: var(--gery500);
  font-weight: 400;
  line-height: 150%;
  margin: 10px 0;
}
.popup > .popup-content .save-popup > .btn-wrapper {
  display: grid;
  row-gap: 5px;
}
.popup > .popup-content .save-popup > .btn-wrapper > button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}
.popup > .popup-content .save-popup > .btn-wrapper > .active-btn {
  color: white;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
.popup > .popup-content .save-popup > .btn-wrapper > .deactive-btn {
  color: var(--grey500);
}
.popup > .popup-content .reject-popup {
  padding: 42px 32px 27px 32px;
}
.popup > .popup-content .reject-popup > .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}
.popup > .popup-content .reject-popup > .reject-content {
  white-space: pre-line;
  font-size: 16px;
  line-height: 1.5;
  width: 280px;
  height: 117px;
  text-align: start;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 11px 16px;
  margin-bottom: 10px;
  overflow-x: hidden;
  background-color: var(--grey100);
}
.popup > .popup-content .reject-popup > .sub {
  font-size: 13px;
  color: var(--gery500);
  font-weight: 400;
  line-height: 150%;
  margin: 10px 0;
}
.popup > .popup-content .reject-popup > .btn-wrapper {
  display: grid;
  row-gap: 5px;
}
.popup > .popup-content .reject-popup > .btn-wrapper > button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}
.popup > .popup-content .reject-popup > .btn-wrapper > .active-btn {
  color: white;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
.popup > .popup-content .reject-popup > .btn-wrapper > .deactive-btn {
  color: var(--grey500);
}
.popup > .popup-content .remove-popup {
  padding: 47px 32px 27px 32px;
  width: 320px;
  height: 262px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.popup > .popup-content .remove-popup > .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}
.popup > .popup-content .remove-popup > .sub {
  font-size: 13px;
  color: var(--gery500);
  font-weight: 400;
  line-height: 150%;
  margin: 10px 0;
}
.popup > .popup-content .remove-popup > .btn-wrapper {
  display: grid;
  row-gap: 5px;
}
.popup > .popup-content .remove-popup > .btn-wrapper > button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}
.popup > .popup-content .remove-popup > .btn-wrapper > .active-btn {
  color: white;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
.popup > .popup-content .remove-popup > .btn-wrapper > .deactive-btn {
  color: var(--grey500);
}
.popup > .popup-content .change-card-popup {
  padding: 30px 28px 21px 28px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 481px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.popup > .popup-content .change-card-popup > .title {
  font-size: 18px;
  font-weight: 700;
}
.popup > .popup-content .change-card-popup .pay-card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 12px 0;
  row-gap: 12px;
  width: 100%;
}
.popup > .popup-content .change-card-popup > .add-button {
  padding: 10px;
  width: 100%;
  height: 50px;
  background-color: #f2e8fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
  border-radius: 5px;
}
.popup > .popup-content .change-card-popup > .add-button > img {
  width: 24px;
}
.popup > .popup-content .change-card-popup > .add-button > .text {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: #7e1ce1;
  white-space: nowrap;
}
.popup > .popup-content .change-card-popup > ul {
  list-style-type: disc;
  padding-left: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 24px;
}
.popup > .popup-content .change-card-popup > ul > li {
  list-style-type: disc;
  font-size: 13px;
  color: var(--grey500);
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}
.popup > .popup-content .change-card-popup > .card-change-btn {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 71px;
}

.popup-on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 768px) {
  #root .popup .popup-content {
    width: calc(100% - 40px);
  }
  #root .popup .popup-content .change-card-popup {
    width: 100%;
  }
  #root .popup .popup-content .change-card-popup > .card-change-btn {
    margin-top: 24px;
  }
}