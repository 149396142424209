#root .category-menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .category-menu-container .category-menu {
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--grey300);
  border-radius: 19.5px;
  margin-right: 8px;
  font-weight: 700;
  color: var(--grey400);
}
#root .category-menu-container .category-menu > p {
  padding: 8px 16px;
}
#root .category-menu-container .selected {
  position: relative;
  border: 1px solid transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
#root .category-menu-container .selected > p {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#root .image-box {
  width: 140px;
  height: 140px;
  background-color: var(--grey100);
  border-radius: 5px;
  margin-right: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
#root .image-box .selected-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .image-box .selected-img .hover-img {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  border-radius: 5px;
  top: 0;
  display: none;
}
#root .image-box .selected-img .hover-img img {
  width: 16px;
  padding-top: 8px;
  padding-right: 7px;
  height: 16px;
  float: right;
}
#root .image-box .selected-img:hover .hover-img {
  display: block;
}
#root .image-box .file {
  display: none;
}
#root .image-box .upload-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--grey400);
}
#root .image-box .upload-text .plus {
  text-align: center;
}
#root .image-box .upload-text .plus img {
  width: 44px;
  height: 44px;
}

@media screen and (max-width: 767px) {
  #root .category-container {
    width: 100%;
  }
  #root .category-container .category-menu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 8px;
  }
}