#root .header-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 66px;
  border-bottom: solid 1px var(--grey200);
  background-color: var(--white);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .header-section .header-wrapper {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 1366px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  padding: 0 41px;
}
#root .header-section .header-wrapper .left-logo {
  display: block;
  width: 195px;
}
#root .header-section .header-wrapper .header-nav-section {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 70px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
  position: relative;
}
#root .header-section .header-wrapper .header-nav-section .header-nav {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
#root .header-section .header-wrapper .header-nav-section .nav-bottom-bar {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 70px;
  height: 3px;
  background-color: var(--main);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .header-section .header-wrapper .header-nav-section .order-bar {
  left: 0;
  background-color: var(--red600);
}
#root .header-section .header-wrapper .header-nav-section .funding-bar {
  left: 105px;
}
#root .header-section .header-wrapper .header-nav-section .story-bar {
  left: 210px;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
#root .header-section .header-wrapper .header-nav-section .etc-bar {
  left: 315px;
  background: -webkit-gradient(linear, left top, right top, from(#7f1ce1), color-stop(99.99%, #e74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7f1ce1 0%, #e74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
#root .header-section .header-wrapper .header-nav-section .not-bar {
  display: none;
}
#root .header-section .header-wrapper .header-profile-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  position: relative;
}
#root .header-section .header-wrapper .header-profile-section .header-user-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-size: 14px;
}
#root .header-section .header-wrapper .header-profile-section .header-user-section .profile-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
#root .header-section .header-wrapper .header-profile-section .header-user-section:hover {
  opacity: 1;
}
#root .header-section .header-wrapper .header-profile-section .header-login-nav-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  font-size: 14px;
  font-weight: 400;
}
#root .header-section .header-wrapper .header-profile-section .header-profile-btn-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .header-section .header-wrapper .header-profile-section .header-profile-btn-section > button, #root .header-section .header-wrapper .header-profile-section .header-profile-btn-section > a {
  height: 31px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .header-section .header-wrapper .header-profile-section .header-profile-btn-section .pqbox-btn {
  background: -webkit-gradient(linear, left top, right top, from(#7f1ce1), color-stop(99.99%, #e74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7f1ce1 0%, #e74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  padding: 0 15px;
}
#root .header-section .sub-nav {
  position: absolute;
  bottom: -57px;
  background-color: white;
  left: 0;
  height: 56px;
  width: 100%;
}
#root .header-section .sub-nav .sub-nav-wrapper {
  height: 100%;
  width: 462px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 48px;
     -moz-column-gap: 48px;
          column-gap: 48px;
}
#root .header-section .sub-nav .sub-nav-wrapper > a {
  font-size: 16px;
  -webkit-animation: rightside 0.2s ease-in-out;
          animation: rightside 0.2s ease-in-out;
}
#root .header-section .sub-nav .sub-nav-wrapper .active-link {
  font-weight: bold;
  color: var(--main);
}

@media screen and (max-width: 1366px) and (min-width: 768px) {
  #root .header-section {
    border-bottom: unset;
  }
  #root .header-section .header-wrapper {
    padding: 0 20px;
    width: 100%;
  }
  #root .header-section .header-wrapper .left-logo {
    width: 178px;
  }
  #root .header-section .header-wrapper .header-nav-section {
    -webkit-column-gap: 24px;
       -moz-column-gap: 24px;
            column-gap: 24px;
  }
  #root .header-section .header-nav-section {
    border-bottom: solid 1px var(--grey200);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -46px;
    left: 0;
    height: 46px;
    background-color: white;
  }
  #root .header-section .header-nav-section .header-nav-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 70px);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 35px;
       -moz-column-gap: 35px;
            column-gap: 35px;
    position: relative;
    height: 100%;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .header-nav {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .nav-bottom-bar {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 70px;
    height: 3px;
    background-color: var(--main);
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .order-bar {
    left: 0;
    background-color: var(--red600);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .funding-bar {
    left: 105px;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .story-bar {
    left: 210px;
    background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
    background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .etc-bar {
    left: 315px;
    background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
    background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .not-bar {
    display: none;
  }
  #root .header-section .sub-nav {
    height: 46px;
    bottom: -92px;
  }
  #root .header-section .sub-nav .sub-nav-wrapper {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .header-section .sub-nav .sub-nav-wrapper > a {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  #root .header-section .header-wrapper {
    padding: 0 20px;
    width: 100%;
  }
  #root .header-section .header-wrapper .left-logo {
    width: 178px;
  }
  #root .header-section .header-wrapper .header-profile-section {
    position: unset;
  }
  #root .header-section .header-wrapper .header-profile-section .header-user-section > .profile-nickname {
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #root .header-section .header-wrapper .header-profile-section .header-profile-btn-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
  }
  #root .header-section .header-wrapper .header-profile-section .header-profile-btn-section > button, #root .header-section .header-wrapper .header-profile-section .header-profile-btn-section > a {
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    border-radius: unset;
  }
  #root .header-section .header-wrapper .header-profile-section .header-profile-btn-section .pqbox-btn {
    background: -webkit-gradient(linear, left top, right top, from(#7f1ce1), color-stop(99.99%, #e74133), to(rgba(127, 28, 225, 0)));
    background: linear-gradient(90deg, #7f1ce1 0%, #e74133 99.99%, rgba(127, 28, 225, 0) 100%);
    color: white;
  }
  #root .header-section .header-nav-section {
    border-bottom: solid 1px var(--grey200);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -46px;
    left: 0;
    height: 46px;
    background-color: white;
  }
  #root .header-section .header-nav-section .header-nav-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 70px);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    position: relative;
    height: 100%;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .header-nav {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .nav-bottom-bar {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 70px;
    height: 3px;
    background-color: var(--main);
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .order-bar {
    left: 0;
    background-color: var(--red600);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .funding-bar {
    left: 90px;
  }
  #root .header-section .header-nav-section .header-nav-wrapper .story-bar {
    left: 180px;
    background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
    background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .etc-bar {
    left: 270px;
    background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
    background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  }
  #root .header-section .header-nav-section .header-nav-wrapper .not-bar {
    display: none;
  }
  #root .header-section .sub-nav {
    height: 46px;
    bottom: -92px;
  }
  #root .header-section .sub-nav .sub-nav-wrapper {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .header-section .sub-nav .sub-nav-wrapper > a {
    font-size: 14px;
  }
}