#root .story-list-page {
  padding: 200px 0;
  width: 1024px;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .story-list-page .story-emty-wrapper {
  height: 500px;
}
#root .story-list-page .page-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}
#root .story-list-page .story-page-filter-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 29px;
}
#root .story-list-page .story-list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  gap: 40px 32px;
  margin-bottom: 15px;
}
#root .story-list-page .view-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .story-list-page .view-page .view-button {
  width: 112px;
  height: 37px;
  background-color: #f5f6f8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (max-width: 1365px) {
  #root .story-list-page {
    width: 728px;
    padding: 200px 0;
  }
  #root .story-list-page .story-page-filter-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    row-gap: 24px;
  }
  #root .story-list-page .story-list-wrapper {
    grid-template-columns: repeat(2, 356px);
    gap: 48px 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .story-list-page {
    width: 100%;
    padding: 180px 20px;
  }
  #root .story-list-page .story-page-filter-section > .categoryfilter {
    width: 100%;
    overflow-y: hidden;
  }
  #root .story-list-page .story-list-wrapper {
    grid-template-columns: 100%;
  }
}