#root .container .login-loading {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .container .login-loading .wrapper .asset {
  width: 138px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}
#root .container .login-loading .wrapper > .title {
  font-size: 30px;
  margin-top: 26.8px;
  color: #9b1ce1;
  text-align: center;
}
#root .container .login-loading .wrapper > .subtitle {
  font-size: 16px;
  margin-top: 10px;
  color: #262626;
  text-align: center;
}

@media screen and (max-width: 538px) {
  #root .container .login-loading .wrapper .asset {
    width: 97px;
  }
  #root .container .login-loading .wrapper > .title {
    font-size: 27px;
    margin-top: 21.6px;
  }
  #root .container .login-loading .wrapper > .subtitle {
    font-size: 13px;
  }
}