.story-detail-page {
  padding: 106px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.story-detail-page .story-detail-wrapper {
  width: 724px;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.story-detail-page .story-detail-wrapper .grey-text {
  font-size: 12px;
  font-weight: 700;
  color: #949494;
}
.story-detail-page .story-detail-wrapper .description-container {
  margin-top: 51px;
}
.story-detail-page .story-detail-wrapper .description-container .category span {
  color: var(--main);
  font-size: 16px;
  font-weight: 700;
}
.story-detail-page .story-detail-wrapper .description-container .description-title {
  margin-top: 8px;
  margin-bottom: 17px;
}
.story-detail-page .story-detail-wrapper .description-container .description-title h3 {
  font-size: 24px;
  font-weight: 700;
}
.story-detail-page .story-detail-wrapper .description-container .author-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 2px solid var(--grey200);
}
.story-detail-page .story-detail-wrapper .description-container .description {
  margin-top: 20px;
}
.story-detail-page .story-detail-wrapper .description-container .description span {
  white-space: pre-line;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}
.story-detail-page hr {
  width: 100%;
  margin-top: 56px;
  margin-bottom: 32px;
  height: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--grey200);
}
.story-detail-page .story-comment-wrapper {
  width: 724px;
  margin: 0 auto;
}
.story-detail-page .story-comment-wrapper .comment-input-container .comment-number-container span {
  font-size: 18px;
  font-weight: 700;
}
.story-detail-page .story-comment-wrapper .comment-input-container .comment-number-container .comment-number {
  color: var(--main);
  margin-left: 4px;
}

@media screen and (max-width: 1365px) {
  #root .story-detail-page {
    padding: 125px 20px;
  }
  #root .story-detail-page .story-detail-wrapper {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .story-detail-page hr {
    width: 100vw;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
}
@media screen and (max-width: 767px) {
  #root .story-detail-page .story-detail-wrapper {
    width: 100%;
  }
}