#root .step-btn {
  width: 195px;
  height: 42px;
  background-color: var(--main);
  border-radius: 5px;
  font-size: 16px;
  color: white;
  font-weight: 700;
  margin-top: 39px;
}
#root .back-step-btn {
  background-color: var(--grey100);
  color: black;
}
#root .order-step-btn {
  background-color: var(--red600);
}
#root .gradient-step-btn {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}