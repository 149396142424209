#root .reward-card {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  padding: 21px 23px 26px 23px;
  border: solid 1px #D9D9D9;
  border-radius: 5px;
  position: relative;
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
}
#root .reward-card > .cancel-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
}
#root .reward-card > .fix-btn {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 24px;
  background-color: black;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}
#root .reward-card > .reward-arrow-section {
  position: absolute;
  right: 60px;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .reward-card > .reward-arrow-section > button {
  height: 32px;
  background-color: #F5F6F8;
  border-radius: 5px;
  padding: 5px;
}
#root .reward-card > .reward-arrow-section > .up-arrow {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
#root .reward-card > .price {
  font-size: 24px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
#root .reward-card > .price .count-box {
  padding: 6px 10px;
  background-color: var(--grey400);
  color: white;
  border-radius: 5px;
  font-size: 13px;
}
#root .reward-card > .price .ordermade-count-box {
  background-color: var(--red100);
  color: var(--red600);
}
#root .reward-card > .price .projects-count-box {
  color: var(--main);
  background-color: var(--violet100);
}
#root .reward-card > .context {
  font-size: 13px;
  white-space: pre-line;
  font-weight: 400;
  line-height: 150%;
  margin: 8px 0 18px;
}
#root .reward-card .reward-card-bottom {
  display: grid;
  row-gap: 4px;
}
#root .reward-card .reward-card-bottom .reward-card-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
  font-size: 13px;
}
#root .reward-card .reward-card-bottom .reward-card-line .reward-card-tag {
  width: 45px;
}
#root .reward-card .reward-card-bottom .reward-card-line .reward-card-content {
  font-weight: 700;
}
#root .detail-reward-card {
  position: relative;
}
#root .detail-reward-card .check-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px var(--grey500);
  position: absolute;
  top: 24px;
  right: 24px;
}
#root .select-reward-card {
  border-color: var(--grey600);
}
#root .select-reward-card .select-reward-content {
  margin-top: 15px;
  border-top: solid 1px var(--grey200);
}
#root .select-reward-card .select-reward-content > .count-section {
  padding: 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 33px;
     -moz-column-gap: 33px;
          column-gap: 33px;
}
#root .select-reward-card .select-reward-content > .count-section > .tag {
  font-size: 13px;
  font-weight: 400;
}
#root .select-reward-card .select-reward-content > .count-section > .count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .select-reward-card .select-reward-content > .count-section > .count > input {
  width: 124px;
  height: 32px;
  border-radius: 5px;
  border: solid 1px var(--grey200);
  padding: 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
}
#root .select-reward-card .select-reward-content > .count-text {
  text-align: center;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 13px;
}