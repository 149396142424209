#root .project-card-wrapper {
  position: relative;
}
#root .project-card-wrapper .card-scrap {
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 5;
}
#root .project-card-wrapper .active-scrap > svg > .scrap-svg-back {
  fill: url(#MyGradient);
  fill-opacity: 1;
}
#root .project-card-wrapper .project-card {
  width: 326px;
  position: relative;
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
}
#root .project-card-wrapper .project-card > .thumbnail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 183px;
  margin-bottom: 12px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#root .project-card-wrapper .project-card > .thumbnail .thumbnail-img {
  width: 326px;
  height: 183px;
}
#root .project-card-wrapper .project-card > .thumbnail .pq-box-svg {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 36px;
  z-index: 1;
}
#root .project-card-wrapper .project-card .etc {
  width: 73px;
  height: 32px;
  border-radius: 5px 0px 0px 0px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
#root .project-card-wrapper .project-card .profill {
  text-align: left;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--grey400);
}
#root .project-card-wrapper .project-card > .title {
  text-align: left;
  height: 45px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: black;
}
#root .project-card-wrapper .project-card .amount {
  padding-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .project-card-wrapper .project-card .amount .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .project-card-wrapper .project-card .amount .percent {
  font-size: 18px;
  color: var(--main);
  font-weight: 700;
  text-align: left;
}
#root .project-card-wrapper .project-card .amount .fail-percent,
#root .project-card-wrapper .project-card .amount .finish-percent,
#root .project-card-wrapper .project-card .amount .re-finish-percent {
  color: var(--grey500);
}
#root .project-card-wrapper .project-card .amount > .reject-btn {
  padding: 6px 10px;
  border-radius: 5px;
  background-color: var(--red100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2px;
     -moz-column-gap: 2px;
          column-gap: 2px;
  font-size: 13px;
  font-weight: 700;
  color: var(--red600);
}
#root .project-card-wrapper .project-card .amount > .reject-btn > .reject-btn-title {
  height: 14px;
}
#root .project-card-wrapper .project-card .amount > .reject-btn > img {
  width: 12px;
}
#root .project-card-wrapper .project-card .amount .days {
  color: var(--main);
  font-weight: bold;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 6px 10px;
  border-radius: 5px;
  background-color: var(--violet100);
}
#root .project-card-wrapper .project-card .amount .fail-days,
#root .project-card-wrapper .project-card .amount .finish-days,
#root .project-card-wrapper .project-card .amount .re-finish-days {
  color: var(--grey500);
  background-color: var(--grey100);
}
#root .project-card-wrapper .project-card .bottom {
  display: grid;
  row-gap: 8px;
  text-align: left;
  margin-bottom: 12px;
  font-size: 13px;
}
#root .project-card-wrapper .project-card .bottom .paylist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}
#root .project-card-wrapper .project-card .bottom .paylist > .text {
  font-weight: 400;
  color: #54585a;
}
#root .project-card-wrapper .project-card .bottom .paylist > .content {
  color: #54585a;
  font-weight: 700;
}
#root .project-card-wrapper .project-card .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .project-card-wrapper .project-card .button-wrapper > .delete {
  width: 50px;
  height: 50px;
  background-color: #f5f6f8;
  border-radius: 5px;
}
#root .project-card-wrapper .project-card .button-wrapper > .delete > img {
  width: 32px;
}
#root .project-card-wrapper .project-card .button-wrapper > .detail-btn {
  border-radius: 5px;
  color: #54585a;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 50px;
  background-color: #f5f6f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
#root .project-card-wrapper .project-card .button-wrapper > .ordermade-btn {
  background-color: var(--red600);
  color: white;
}
#root .project-card-wrapper .project-card .button-wrapper > .projects-btn {
  background-color: var(--main);
  color: white;
}
#root .project-card-wrapper .project-card .line {
  margin: 12px 0;
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
}
#root .project-card-wrapper .project-card .answer-text {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-top: 16px;
}
#root .project-card-wrapper .sk-project-card > .thumbnail {
  display: block;
}
#root .project-card-wrapper .ordermade-project-card > .amount > .percent {
  color: var(--red600);
}
#root .project-card-wrapper .ordermade-project-card > .amount .fail-percent,
#root .project-card-wrapper .ordermade-project-card > .amount .finish-percent,
#root .project-card-wrapper .ordermade-project-card > .amount .re-finish-percent {
  color: var(--grey500);
}
#root .project-card-wrapper .ordermade-project-card > .amount .days {
  color: var(--red600);
  background-color: var(--red100);
}
#root .project-card-wrapper .ordermade-project-card > .amount .fail-days,
#root .project-card-wrapper .ordermade-project-card > .amount .finish-days,
#root .project-card-wrapper .ordermade-project-card > .amount .re-finish-days {
  color: var(--grey500);
  background-color: var(--grey100);
}
#root .project-card-wrapper .open-ready-state > .etc {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
#root .project-card-wrapper .open-reject-state > .etc {
  background-color: var(--red600);
}
#root .project-card-wrapper .open-reject-state .reject-amount > .percent {
  color: var(--red600);
}
#root .project-card-wrapper .open-fail-state .fail-amount > .percent {
  color: var(--grey400);
}
#root .project-card-wrapper .open-fail-state .fail-amount > .days {
  background-color: var(--grey100);
  color: var(--grey500);
}

@media screen and (max-width: 1365px) {
  #root .project-card-wrapper .project-card {
    width: 356px;
  }
  #root .project-card-wrapper .project-card > .thumbnail .thumbnail-img {
    width: 356px;
    height: 200px;
  }
}
@media screen and (max-width: 767px) {
  #root .project-card-wrapper .project-card {
    width: 100%;
    height: 100%;
    display: grid;
  }
  #root .project-card-wrapper .project-card > .thumbnail {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #root .project-card-wrapper .project-card > .thumbnail .thumbnail-img {
    width: 100%;
    height: 25vw;
    min-width: 144px;
  }
  #root .project-card-wrapper .project-card > .thumbnail .pq-box-svg {
    left: 5px;
    top: 5px;
    width: 30px;
  }
  #root .project-card-wrapper .project-card .title {
    min-height: 48px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 15px;
    text-align: start;
  }
  #root .project-card-wrapper .project-card .amount {
    -ms-flex-item-align: end;
        align-self: flex-end;
    padding-top: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 4px;
  }
  #root .project-card-wrapper .project-card .amount .percent {
    font-size: 16px;
    padding-left: 2px;
  }
  #root .project-card-wrapper .sk-project-card > .thumbnail {
    height: 30vw;
  }
}