@charset "UTF-8";
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
#root .shake {
  -webkit-animation: shake 0.5s ease-in-out;
          animation: shake 0.5s ease-in-out;
}
#root .projectpayment-page {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  padding-bottom: 200px;
  padding-top: 161px;
  width: 1024px;
  margin: 0 auto;
}
#root .projectpayment-page > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  position: relative;
}
#root .projectpayment-page > .wrapper > .left {
  width: 672px;
}
#root .projectpayment-page > .wrapper .projectpayment-info {
  margin-bottom: 35px;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .projectpayment-page > .wrapper .projectpayment-info .right .etc-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .projectpayment-page > .wrapper .projectpayment-info .right .etc-wrapper .percent {
  font-size: 18px;
  color: #9b1ce1;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-info .right .etc-wrapper .days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;
  background: var(--purple-violet-100, #f2e8fc);
  color: #9b1ce1;
}
#root .projectpayment-page > .wrapper .projectpayment-info .right .title {
  margin-bottom: 16px;
  width: 300px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
#root .projectpayment-page > .wrapper .projectpayment-info .right .catagory {
  font-size: 14px;
  color: #949494;
  font-weight: 700;
  margin-bottom: 4px;
}
#root .projectpayment-page > .wrapper .projectpayment-info .left .thumbnail {
  border-radius: 5px;
  background-color: #949494;
  width: 205px;
  height: 115px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward > .title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .projectpayment-page > .wrapper .projectpayment-reward > .title .option-btn {
  width: 76px;
  height: 32px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward > .reward-card {
  margin-bottom: 10px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box {
  margin-bottom: 56px;
  position: relative;
  padding: 24px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #dbdbdb;
  width: 100%;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box > .top {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box > .top .reward-price {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box > .top .reward-count {
  border-radius: 5px;
  width: 74px;
  height: 28px;
  background-color: #f2e8fc;
  font-size: 13px;
  font-weight: 700;
  color: #9b1ce1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-context {
  margin-bottom: 12px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer {
  bottom: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 624px;
  height: 76px;
  background-color: #f2e8fc;
  border-radius: 5px;
  padding: 16px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .left > .title-wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .left > .title-wrapper > .title {
  font-size: 13px;
  font-weight: 400;
  width: 50px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .left > .title-wrapper > .text {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}
#root .projectpayment-page > .wrapper .projectpayment-reward .reward-box .reward-footer > .right .pay {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-support {
  width: 100%;
  position: relative;
  margin-bottom: 56px;
}
#root .projectpayment-page > .wrapper .projectpayment-support > .title {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-support > .text {
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
}
#root .projectpayment-page > .wrapper .projectpayment-support > input {
  margin-top: 16px;
  border-radius: 5px;
  padding: 0 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #dbdbdb;
}
#root .projectpayment-page > .wrapper .projectpayment-support .text-won {
  position: absolute;
  right: 16px;
  bottom: 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-address {
  margin-bottom: 56px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .projectpayment-page > .wrapper .projectpayment-address > .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-address .input-wrapper > .title {
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-address .input-wrapper .input-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .projectpayment-page > .wrapper .projectpayment-address .input-wrapper .input-address > input {
  width: 100%;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 0 16px;
}
#root .projectpayment-page > .wrapper .projectpayment-address .input-wrapper .input-address > .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  padding: 0 16px;
  border: 1px solid #dbdbdb;
  width: 183px;
  height: 49px;
  color: #949494;
}
#root .projectpayment-page > .wrapper .order-file-wrapper {
  margin-top: 24px;
  display: grid;
  row-gap: 10px;
}
#root .projectpayment-page > .wrapper .projectpayment-card {
  margin-top: 24px;
  width: 100%;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .title-wrapper > .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .title-wrapper > .sub {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .card-list-section {
  margin: 12px 0;
  display: grid;
  row-gap: 12px;
  grid-template-columns: 320px;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .card-none-box {
  margin-top: 19px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 16px;
  width: 320px;
  height: 90px;
  border-radius: 5px;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #949494;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .card-none-box > img {
  width: 28px;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .add-button {
  margin-bottom: 24px;
  border-radius: 5px;
  padding: 16px;
  width: 320px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  background-color: var(--violet100);
}
#root .projectpayment-page > .wrapper .projectpayment-card > .add-button > svg > circle {
  fill: white;
}
#root .projectpayment-page > .wrapper .projectpayment-card > .add-button > svg g > path {
  stroke: var(--main);
}
#root .projectpayment-page > .wrapper .projectpayment-card > .add-button > svg defs rect {
  fill: var(--main);
}
#root .projectpayment-page > .wrapper .projectpayment-card > .add-button > .text {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: var(--main);
}
#root .projectpayment-page > .wrapper .projectpayment-card .ordermade-add-button {
  background-color: var(--red100);
}
#root .projectpayment-page > .wrapper .projectpayment-card .ordermade-add-button > svg > circle {
  fill: white;
}
#root .projectpayment-page > .wrapper .projectpayment-card .ordermade-add-button > svg g > path {
  stroke: var(--red600);
}
#root .projectpayment-page > .wrapper .projectpayment-card .ordermade-add-button > svg defs rect {
  fill: var(--red600);
}
#root .projectpayment-page > .wrapper .projectpayment-card .ordermade-add-button > .text {
  color: var(--red600);
}
#root .projectpayment-page > .wrapper .projectpayment-card > ul {
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  color: #54585a;
}
#root .projectpayment-page > .wrapper .projectpayment-card > ul > ul,
#root .projectpayment-page > .wrapper .projectpayment-card > ul li::before {
  content: "•";
  margin-right: 5px;
}
#root .projectpayment-page > .wrapper .payment-confirmation {
  position: sticky;
  top: 309px;
}
#root .projectpayment-page > .wrapper .payment-confirmation > .title {
  margin-bottom: 9px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box {
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 320px;
  height: 392px;
  border: 1px solid #d9d9d9;
  padding: 19px 16px;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .pay-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .pay-wrapper > .text {
  width: 80px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .pay-wrapper > .pay {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .text-caution {
  padding: 8px 0px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .text-caution .dot-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .text-caution .dot-wrapper > .dot {
  margin-top: 6px;
  margin-right: 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #54585a;
}
#root .projectpayment-page > .wrapper .payment-confirmation .payment-box .text-caution .dot-wrapper > .text {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
#root .projectpayment-page > .wrapper .funding-btn {
  width: 100%;
  height: 50px;
  background-color: #7e1ce1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: white;
  border-radius: 5px;
  margin-top: 16px;
}
#root .projectpayment-page > .wrapper .err-btn {
  background-color: #fdeceb;
  color: #e74133;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 16px;
  cursor: unset;
}
#root .ordermade-payment-page > .wrapper .projectpayment-info > .right .etc-wrapper .percent {
  color: var(--red600);
}
#root .ordermade-payment-page > .wrapper .projectpayment-info > .right .etc-wrapper .days {
  color: var(--red600);
  background-color: var(--red100);
}
#root .ordermade-payment-page > .wrapper .projectpayment-reward .reward-box > .top .reward-count {
  color: var(--red600);
  background-color: var(--red100);
}
#root .ordermade-payment-page > .wrapper .projectpayment-reward .reward-box .reward-footer {
  background-color: var(--red100);
}
#root .ordermade-payment-page > .wrapper .payment-confirmation .funding-btn {
  background-color: var(--red600);
}

@media screen and (max-width: 1439px) {
  #root .projectpayment-page {
    width: 728px;
  }
  #root .projectpayment-page .wrapper {
    -webkit-column-gap: 18px;
       -moz-column-gap: 18px;
            column-gap: 18px;
  }
  #root .projectpayment-page .wrapper > .left {
    width: 382px;
  }
  #root .projectpayment-page .wrapper > .left .projectpayment-reward .reward-box .reward-footer {
    width: 330px;
  }
  #root .projectpayment-page .wrapper > .left .input-section .input-main .input-search > .adress-main {
    width: 272px;
  }
  #root .projectpayment-page .wrapper > .left .input-section .input-main .input-search > .adress-number {
    margin-top: 22px;
    padding: 0 16px;
    width: 102px;
    height: 50px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }
  #root .projectpayment-page .wrapper > .left .input-section .input-main .search-wrapper > img {
    right: 130px;
    top: 35px;
  }
  #root .projectpayment-page .wrapper > .left .order-file-wrapper > .common-input .common-file-label > .title {
    font-size: 14px;
  }
  #root .projectpayment-page .wrapper > .left .projectpayment-card > .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    row-gap: 5px;
  }
  #root .projectpayment-page .wrapper > .left .projectpayment-card > ul {
    font-size: 11px;
  }
}
@media screen and (max-width: 767px) {
  #root .projectpayment-page {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 150px 20px;
  }
  #root .projectpayment-page > .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-column-gap: 18px;
       -moz-column-gap: 18px;
            column-gap: 18px;
  }
  #root .projectpayment-page > .wrapper > .left {
    width: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box {
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box .reward-footer {
    padding: 12px;
    width: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box .reward-footer > .left .title-wrapper .title {
    font-size: 11px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box .reward-footer > .left .title-wrapper .text {
    font-size: 11px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box .reward-footer > .right > .pay {
    font-size: 15px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-reward .reward-box .reward-footer > .right > .option-btn {
    width: 55px;
    font-size: 11px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-info > .right {
    margin-top: 8px;
  }
  #root .projectpayment-page > .wrapper > .left .input-section .input-main .input-search > .adress-main {
    width: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .input-section .input-main .input-search > .adress-number {
    margin-top: 8px;
    width: 100%;
    height: 50px;
  }
  #root .projectpayment-page > .wrapper > .left .input-section .input-main .search-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .projectpayment-page > .wrapper > .left .input-section .input-main .search-wrapper > img {
    right: 16px;
    top: 35px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-support > .text {
    font-size: 11px;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-card > .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-card > .card-list-section {
    grid-template-columns: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-card > .card-none-box {
    width: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-card > .add-button {
    width: 100%;
  }
  #root .projectpayment-page > .wrapper > .left .projectpayment-card > ul {
    font-size: 10px;
    width: 330px;
  }
  #root .projectpayment-page > .wrapper > .right .payment-confirmation {
    position: unset;
    padding-top: 49px;
  }
  #root .projectpayment-page > .wrapper > .right .payment-confirmation > .payment-box {
    width: 100%;
  }
}