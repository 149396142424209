#root .input-section .title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
}
#root .input-section .title > span {
  color: red;
  font-size: 11px;
  padding-left: 2px;
}
#root .input-section .input-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}
#root .input-section .input-main .search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
  width: 100%;
  position: relative;
}
#root .input-section .input-main .search-wrapper > img {
  position: absolute;
  right: 210px;
  top: 35px;
  width: 28px;
}
#root .input-section .input-main .input-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
#root .input-section .input-main .input-search .main-title {
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 13px;
}
#root .input-section .input-main .input-search .main-title > span {
  font-size: 11px;
  color: var(--grey400);
}
#root .input-section .input-main .input-search > .adress-main {
  padding: 0 16px;
  width: 480px;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
#root .input-section .input-main .input-search > .adress-number {
  margin-top: 22px;
  padding: 0 16px;
  width: 183px;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background: white;
}
#root .input-section .input-main .input-search > .adress-main:-moz-read-only, #root .input-section .input-main .input-search > .adress-number:-moz-read-only {
  background-color: white;
}
#root .input-section .input-main .input-search > .adress-main:read-only, #root .input-section .input-main .input-search > .adress-number:read-only {
  background-color: white;
}
#root .input-section .input-main .adress-sub {
  padding: 0 16px;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  border: 1px solid #dbdbdb;
}
#root .input-section .post-wrapper {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}
#root .input-section .post-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: inside 0.2s;
          animation: inside 0.2s;
}
#root .input-section .post-active > .back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}
#root .input-section .post-active .post-section {
  max-width: 800px;
  width: 100vw;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 50px;
}
#root .input-section .post-active .post-section > img {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
#root .input-section .post-active .post-section #postcode {
  width: 800px;
}