@charset "UTF-8";
#root .main-banner-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
}
#root .main-banner-section .banner-prjt-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .main-banner-section .banner-prjt-section > .title-section {
  margin-bottom: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .main-banner-section .banner-prjt-section > .title-section > .title {
  font-size: 24px;
  font-weight: 700;
}
#root .main-banner-section .banner-prjt-section > .title-section > a {
  font-size: 13px;
  color: #898989;
  font-weight: 400;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper {
  display: grid;
  row-gap: 24px;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .img-wrapper {
  width: 92px;
  height: 92px;
  border-radius: 5px;
  overflow: hidden;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .img-wrapper > img {
  width: 92px;
  height: 92px;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .content > .author {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .content > .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  margin: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .content > .time {
  font-size: 13px;
  color: var(--grey400);
  line-height: 150%;
}
#root .main-banner-section .banner-prjt-section .banner-emty {
  width: 100%;
  height: 512px;
}

@media screen and (max-width: 1365px) {
  #root .main-banner-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 48px;
  }
  #root .main-banner-section .banner-prjt-section .banner-emty {
    height: 191px;
  }
  #root .main-banner-section .banner-prjt-section .banner-emty > .emty-list > .emty-sub {
    white-space: nowrap;
  }
  #root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    overflow-x: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
  }
  #root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    row-gap: 8px;
  }
  #root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .img-wrapper {
    width: 170px;
    height: 96px;
  }
  #root .main-banner-section .banner-prjt-section .banner-prjt-list-wrapper .banner-prjt-card > .img-wrapper > img {
    width: 170px;
    height: 96px;
  }
  #root .main-banner-section .banner-prjt-section ::-webkit-scrollbar {
    height: 5px;
    /* 가로 스크롤바 높이 */
  }
  #root .main-banner-section .banner-prjt-section ::-webkit-scrollbar-thumb {
    background-color: var(--violet400);
    border-radius: 5px;
    /* 스크롤바 색상 */
  }
}
@media screen and (max-width: 767px) {
  #root .main-banner-section .banner-prjt-section {
    padding: 0 20px;
  }
  #root .main-banner-section .banner-prjt-section .banner-emty > .emty-list > .emty-sub {
    white-space: pre-line;
    line-height: 150%;
  }
}