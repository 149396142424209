@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import url("react-loading-skeleton/dist/skeleton.css");
@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes topside {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes topside {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes rightside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes rightside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.react-loading-skeleton {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

* {
  --main: #7f1ce1;
  --violet900: #290040;
  --violet800: #52097a;
  --violet700: #7f0cbe;
  --violet500: #af49e7;
  --violet400: #c377ed;
  --violet300: #d7a4f3;
  --violet200: #ebd2f9;
  --violet100: #f9f2fd;
  --grey600: #242627;
  --grey500: #54585a;
  --grey400: #949494;
  --grey300: #c3c3c3;
  --grey200: #dbdbdb;
  --grey100: #f5f6f8;
  --red600: #E74133;
  --red100: #fdeceb;
  --white: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-family: "pretendard", sans-serif;
}
* [placeholder]:empty:focus::before {
  content: "";
}
* [contenteditable=true] {
  outline: unset;
}
* [contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
  color: #bfbfbf;
  font-size: 17px;
  font-family: "pretendard", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  color: black;
}

main {
  min-height: 100vh;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: "pretendard", sans-serif;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}

button,
a,
label {
  padding: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  background-color: unset;
  color: black;
}
button:focus,
a:focus,
label:focus {
  outline: none;
}
button:hover,
a:hover,
label:hover {
  opacity: 0.7;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}
#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}