#root .page-404 {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .page-404 .wrapper {
  width: 398px;
  margin: 0 auto;
}
#root .page-404 .wrapper .symbol {
  width: 106.035px;
  height: 87.988px;
  margin: 0 auto;
}
#root .page-404 .wrapper .slogan {
  font-size: 24px;
  margin-top: 33.2px;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
}
#root .page-404 .wrapper .slogan2 {
  margin-top: 10px;
  font-size: 14px;
  color: var(--text);
  line-height: 1.57;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 32px;
}
#root .page-404 .wrapper a {
  width: 240px;
  height: 42px;
  margin-top: 42px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  margin: 0 auto;
  background: var(--main);
}
@media screen and (max-width: 767px) {
  #root .page-404 {
    position: relative;
    z-index: unset;
  }
  #root .page-404 .wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .page-404 .wrapper .symbol {
    width: 59.4vw;
  }
  #root .page-404 .wrapper .slogan {
    font-size: 4.8vw;
    margin-top: 18px;
  }
  #root .page-404 .wrapper .slogan2 {
    margin-top: 3px;
    font-size: 3.2vw;
  }
  #root .page-404 .wrapper .goHome {
    width: 74.1vw;
    height: 9.8vw;
  }
  #root .page-404 .wrapper .goHome span {
    font-size: 3.4vw;
  }
}