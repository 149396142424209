#root .pqbox-write-page {
  padding: 104px 0;
}
#root .pqbox-write-page > .wrapper {
  width: 1024px;
  margin: 0 auto;
}
#root .pqbox-write-page > .wrapper .pqbox-nav-section {
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  margin-top: 16px;
}
#root .pqbox-write-page > .wrapper .pqbox-nav-section > .write-nav {
  border: solid 1px var(--grey300);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
  color: var(--grey400);
}
#root .pqbox-write-page > .wrapper .pqbox-nav-section > .write-nav > p {
  padding: 8px 16px;
  color: #949494;
  font-weight: bold;
  white-space: nowrap;
}
#root .pqbox-write-page > .wrapper .pqbox-nav-section > .now-nav {
  position: relative;
  border: 1px solid transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
#root .pqbox-write-page > .wrapper .pqbox-nav-section > .now-nav > p {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#root .pqbox-write-page > .wrapper .write-pqbox-title {
  font-size: 20px;
  font-weight: 700;
}
#root .pqbox-write-page > .wrapper .write-pqbox-title > span {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 7px;
}
#root .pqbox-write-page > .wrapper .pqbox-write-content-section {
  margin-top: 24px;
  border-top: solid 1px var(--grey200);
  padding-top: 8px;
}
#root .pqbox-write-page > .wrapper .pqbox-write-content-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .pqbox-write-page > .wrapper .category-step-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .pqbox-write-page .grey-line {
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
  margin: 32px 0 24px;
}