#root .home-banner-section {
  background-color: var(--grey100);
  padding: 52px 0 78px;
}
#root .home-banner-section > .home-wrapper {
  -webkit-animation: inside 0.5s ease-in-out;
          animation: inside 0.5s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper .home-banner-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
  width: 495px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
  height: 278px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider > .back-img > img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 495px;
  height: 278px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
  padding-top: 24px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
  font-size: 26px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
  height: 58px;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper .home-banner-link {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 42px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  color: white;
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper .order-banner-link {
  background-color: var(--red600);
}
#root .home-banner-section > .home-wrapper .home-banner-wrapper .funding-banner-link {
  background-color: var(--main);
}

@media screen and (max-width: 1365px) {
  #root .home-banner-section > .home-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 80px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
    width: 100%;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    height: 408px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper .img-slider .back-img > img {
    width: 100%;
    height: 408px;
  }
}
@media screen and (max-width: 767px) {
  #root .home-banner-section > .home-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 80px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
    width: 100%;
    row-gap: 24px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    height: 48vw;
    min-height: unset;
    border-radius: 5px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider {
    width: 100%;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider .back-img > img {
    width: 100%;
    height: 48vw;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .slide-wrapper {
    right: 10px;
    bottom: 10px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
    padding-top: unset;
    width: 100%;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content {
    padding: unset;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
    font-size: 23px;
  }
  #root .home-banner-section > .home-wrapper .home-banner-wrapper > .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
    margin: 8px 0 0 0;
    height: 30px;
  }
}