#root .comment-list-section {
  display: grid;
  row-gap: 8px;
}
#root .comment-list-section .comment-card {
  width: 100%;
  background-color: #F5F6F8;
  border-radius: 5px;
  min-height: 143px;
  padding: 17px 16px 14px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .comment-list-section .comment-card .comment-top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .comment-list-section .comment-card .comment-top-section .profile-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .comment-list-section .comment-card .comment-top-section .profile-section > .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
#root .comment-list-section .comment-card .comment-top-section .profile-section > .circle > img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .comment-list-section .comment-card .comment-top-section .profile-section > .author {
  font-size: 18px;
  font-weight: 700;
}
#root .comment-list-section .comment-card .comment-top-section .right-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .comment-list-section .comment-card .comment-top-section .right-btn-wrapper > button {
  font-size: 12px;
  color: var(--grey400);
  font-weight: 400;
}
#root .comment-list-section .comment-card .comment-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin: 16px 0;
  white-space: pre-line;
}
#root .comment-list-section .comment-card .comment-content > span {
  color: var(--grey400);
}
#root .comment-list-section .comment-card .fix-input {
  width: 100%;
  height: 42px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  padding: 8px 10px;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .comment-list-section .comment-card .comment-write-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .comment-list-section .comment-card .comment-write-bottom .secret-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  padding: 12px 16px;
  height: 44px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  max-width: 320px;
  width: 80%;
}
#root .comment-list-section .comment-card .comment-write-bottom .secret-btn > .circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  border: solid 1px var(--grey200);
}
#root .comment-list-section .comment-card .comment-write-bottom .secret-btn > .secret-btn-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
#root .comment-list-section .comment-card .comment-write-bottom .submit-btn {
  width: 62px;
  height: 42px;
  background-color: var(--grey600);
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}
#root .comment-list-section .comment-card .comment-timestamp {
  font-size: 12px;
  color: var(--grey400);
  font-weight: 400;
}
#root .comment-list-section .comment-card .comment-timestamp > span {
  color: var(--grey600);
  margin-left: 8px;
}
#root .comment-list-section .reply-card {
  position: relative;
  padding-left: 50px;
}
#root .comment-list-section .reply-card > .reply-arrow {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 28px;
}
#root .comment-list-section .reply-card .reply-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .comment-list-section .reply-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .comment-list-section .reply-comment > .reply-arrow {
  width: 28px;
}
#root .comment-list-section .reply-comment > .comment-right {
  width: 100%;
}