#root .story-list-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
  height: 43px;
}
#root .story-list-search > .sort-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  height: 100%;
  padding: 0 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .story-list-search > .sort-btn > .circle {
  width: 16px;
  height: 16px;
  border: solid 1px var(--grey200);
  border-radius: 50%;
}
#root .story-list-search > .sort-btn > span {
  font-size: 14px;
  color: var(--grey500);
  font-weight: 400;
}
#root .story-list-search .story-write-nav {
  width: 100px;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 1365px) {
  #root .story-list-search {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #root .story-list-search .story-write-nav {
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  #root .story-list-search > .common-search-input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  #root .story-list-search > .common-search-input > input {
    width: 90%;
  }
  #root .story-list-search .story-write-nav {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 10px;
    z-index: 10;
  }
  #root .story-list-search .story-write-nav > img {
    width: 44px;
  }
}