#root .detail-img-container {
  height: 724px;
  border-radius: 5px;
  overflow: hidden;
}
#root .detail-img-container img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
#root .slide-img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
#root .slide-img-container .slide-wide-container {
  width: 138px;
  height: 138px;
}
#root .slide-img-container .slide-wide-container > img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
#root .slide-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  color: #949494;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .slide-btn-container .grey-text {
  height: 100%;
}
#root .slide-btn-container .btn {
  margin-left: 12px;
}
#root .slide-btn-container .btn button {
  padding-top: 3px;
}
#root .slide-btn-container .btn .left-btn {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
  margin-right: 8px;
}
#root .author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .author-info span {
  font-size: 12px;
  font-weight: 500;
}
#root .author-info span:nth-child(2) {
  margin-top: 4px;
}
#root .edit-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .edit-container button {
  font-weight: 700;
}
#root .edit-container .delete {
  margin-left: 16px;
  margin-right: 16px;
}

@media screen and (max-width: 767px) {
  #root .detail-img-container {
    height: calc(100vw - 40px);
  }
  #root .slide-img-container .slide-wide-container {
    width: 57px;
    height: 57px;
  }
}