#root .project-list-top {
  padding-top: 61px;
}
#root .project-list-top .project-top-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .project-list-top .project-top-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}
#root .project-list-top .project-top-wrapper > .title-wrapper > .title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
#root .project-list-top .project-top-wrapper .project-list-top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .project-list-top .filter-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .project-list-top .filter-btn-wrapper .filter-back-section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section {
  position: relative;
  width: 153px;
  height: 32px;
  z-index: 50;
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section .filter-button {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: absolute;
  font-size: 13px;
  font-weight: 400;
  height: 32px;
  width: 153px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  cursor: pointer;
  z-index: 50;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section .filter-button > img {
  position: absolute;
  width: 20px;
  right: 12px;
  top: 6px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section .filter-button > .open-arrow {
  -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section .filter-button > button {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  height: 32px;
  padding: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: start;
}
#root .project-list-top .filter-btn-wrapper .filter-btn-section .open-filter {
  background-color: white;
  height: 135px;
}
#root .project-list-top .pq-box {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 12px;
  width: 163px;
  border: 5px;
  height: 32px;
  background-color: #f5f6f8;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .project-list-top .pq-box .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .project-list-top .pq-box .left .check {
  background-color: white;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
}
#root .project-list-top .pq-box .right .hover {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
#root .project-list-top .pq-box .right .hover .pq-box-hover {
  display: none;
}
#root .project-list-top .pq-box .right .hover:hover .pq-box-hover {
  line-height: 150%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  width: 144px;
  height: 93px;
  position: absolute;
  bottom: -100px;
  right: 0px;
  font-size: 12px;
  z-index: 100;
}
#root .project-list-top .pq-box .right .hover:hover .pq-box-hover > span {
  font-weight: 700;
}
#root .project-list-top .pq-box-text {
  -webkit-animation: fadeOut 2.8s ease-in;
          animation: fadeOut 2.8s ease-in;
  top: 20px;
  right: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  width: 210px;
  height: 26px;
  color: white;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
  border-radius: 0px;
}
#root .project-list-top .pq-box-text:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 5px 0;
  border-color: black transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -10px;
  left: 100px;
}
#root .project-list-top .check-box {
  background-color: var(--violet100);
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
@media screen and (max-width: 1365px) and (min-width: 768px) {
  .project-list-top > .project-top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .project-list-top > .project-top-wrapper > .title-wrapper {
    padding-top: 90px;
  }
  .project-list-top > .project-top-wrapper > .title-wrapper > .categoryfilter {
    margin-right: auto;
    margin-bottom: 24px;
  }
  .project-list-top > .project-top-wrapper > .project-list-top-right {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .project-top {
    padding-top: 31px;
  }
  .project-top .filter-button {
    top: 33px;
  }
  .project-top .pq-box .right .hover:hover .pq-box-hover {
    top: 70px;
    right: 0px;
  }
  .project-top .pq-box-text {
    top: -10px;
    right: -20px;
  }
}
@media screen and (max-width: 767px) {
  #root .project-list-top {
    padding-top: 31px;
  }
  #root .project-list-top > .project-top-wrapper {
    padding-top: 90px;
    display: block;
  }
  #root .project-list-top > .project-top-wrapper > .title-wrapper > .mb-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  #root .project-list-top > .project-top-wrapper > .title-wrapper .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  #root .project-list-top .filter-btn-wrapper {
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  #root .project-list-top .filter-btn-wrapper .filter-btn-section {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  #root .project-list-top .filter-btn-wrapper .filter-btn-section .filter-button {
    width: 100%;
  }
  #root .project-list-top .wrapper {
    padding-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
  }
  #root .project-list-top .color {
    color: #949494;
  }
  #root .project-list-top .pq-box {
    font-size: 12px;
    width: 152px;
    height: 32px;
  }
  #root .project-list-top .pq-box .left .check {
    width: 16px;
    height: 16px;
  }
  #root .project-list-top .pq-box .right .hover:hover .pq-box-hover {
    top: 50px;
    right: 0px;
  }
  #root .project-list-top .pq-box-text {
    top: -10px;
    right: 0px;
  }
}