#root .pqbox-list-emty {
  width: 100%;
  height: 212px;
  border-radius: 5px;
  border: solid 1px var(--grey200);
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .pqbox-list-emty > img {
  width: 32px;
}
#root .pqbox-list-emty > .title {
  font-size: 16px;
  font-weight: 700;
  margin: 5px 0;
}
#root .pqbox-list-emty > .sub {
  font-size: 13px;
  color: var(--grey500);
  font-weight: 400;
}
#root .pqbox-list-emty .new-reward-btn {
  width: 150px;
  height: 42px;
  font-size: 16px;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  border-radius: 10px;
  margin-top: 16px;
}