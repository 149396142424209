#root .notice {
  background-color: white;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding-top: 161px;
}
#root .notice .wrapper {
  background-color: white;
  width: 1108px;
  margin: 0 auto;
}
#root .notice .wrapper .top-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .notice .wrapper .top-wrapper > .title {
  font-size: 30px;
  font-weight: bold;
}
#root .notice .wrapper .top-wrapper .top_right {
  position: absolute;
  right: 0;
  bottom: -2px;
  border-bottom: solid 1px #c6c6c6;
}
#root .notice .wrapper .top-wrapper .top_right .input_wrapper {
  width: 218px;
  height: 30px;
  position: relative;
  padding: 0 10px 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .notice .wrapper .top-wrapper .top_right .input_wrapper input {
  width: 180px;
}
#root .notice .wrapper .top-wrapper .top_right .input_wrapper img {
  position: absolute;
  width: 19.4px;
  height: 19.4px;
  bottom: 11px;
  right: 5px;
}
#root .notice .wrapper .body {
  padding: 44px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
#root .notice .wrapper .body .notice-emty-wrapper {
  height: 500px;
}

@media screen and (max-width: 1439px) {
  #root .notice {
    padding-top: 159px;
  }
  #root .notice .wrapper {
    background-color: white;
    position: relative;
    padding: 27px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
  }
  #root .notice .wrapper .top-wrapper .top_right .input_wrapper {
    width: 170px;
  }
  #root .notice .wrapper .top-wrapper .top_right .input_wrapper > input {
    width: 120px;
  }
  #root .notice .wrapper .top-wrapper .top_right .input_wrapper img {
    width: 16.4px;
    height: 16.4px;
  }
  #root .notice .wrapper .top .title {
    font-size: 15px;
  }
}