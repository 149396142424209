#root .main-page {
  padding-top: 161px;
}
#root .main-page .main-wrapper {
  width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  #root .main-page {
    padding-top: 159px;
  }
  #root .main-page .main-wrapper {
    width: 728px;
  }
}
@media screen and (max-width: 767px) {
  #root .main-page {
    padding-top: 159px;
  }
  #root .main-page .main-wrapper {
    width: 100%;
  }
}