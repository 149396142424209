#root .common-search-input {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
  border: solid 1px var(--grey200);
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 14px;
}
#root .common-search-input > input {
  height: 100%;
}
#root .common-search-input > img {
  width: 23px;
}