#root .story-card .story-card-top .story-card-img {
  height: 320px;
}
#root .story-card .story-card-top .story-card-img > img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .story-card .story-card-top > .category {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 14px 0 4px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#root .story-card .story-card-top > .loading-category {
  width: 12%;
  height: 13px;
}
#root .story-card .story-card-top > .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .story-card .story-card-top > .loading-title {
  height: 10px;
}
#root .story-card .story-card-top > .content {
  margin: 4px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  min-height: 42px;
}
#root .story-card .story-card-top > .loading-content {
  height: 50px;
}
#root .story-card .story-card-top > .card-time {
  font-size: 13px;
  color: var(--grey400);
  font-weight: 700;
}
#root .story-card .story-card-top > .loading-card-time {
  width: 15%;
  height: 15px;
}
#root .story-card .story-card-bottom {
  margin-top: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .story-card .story-card-bottom > .author {
  font-size: 14px;
  color: var(--grey500);
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  #root .story-card .story-card-top .story-card-img {
    height: calc(100vw - 40px);
  }
}