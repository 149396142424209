#root .pqbox-write-top > .title-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .pqbox-write-top > .title-section > .title {
  font-size: 24px;
  font-weight: 700;
}
#root .pqbox-write-top > .title-section .write-header-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .pqbox-write-top > .title-section .write-header-btn-wrapper > button {
  width: 84px;
  height: 42px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}
#root .pqbox-write-top > .title-section .write-header-btn-wrapper > .header-back-btn {
  background-color: var(--grey100);
  color: var(--grey400);
}
#root .pqbox-write-top > .title-section .write-header-btn-wrapper > .save-btn {
  background-color: var(--grey500);
  color: white;
}
#root .pqbox-write-top > .title-section .write-header-btn-wrapper > .insert-btn {
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
}
#root .pqbox-write-top > .project-info-section {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .etc-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .etc-wrapper .percent {
  font-size: 18px;
  color: var(--main);
  font-weight: 700;
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .etc-wrapper .days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  border-radius: 5px;
  background: var(--purple-violet-100, #f2e8fc);
  color: var(--main);
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .ordermade-etc-wrapper .percent {
  color: var(--red600);
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .ordermade-etc-wrapper .days {
  background-color: var(--red100);
  color: var(--red600);
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
#root .pqbox-write-top > .project-info-section .project-content-wrapper .catagory {
  font-size: 14px;
  color: #949494;
  font-weight: 700;
  margin-bottom: 4px;
}