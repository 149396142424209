#root .pqbox-category-content-wrapper {
  margin: 24px 0 65px;
  display: grid;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  grid-template-columns: repeat(4, 130px);
  grid-template-rows: 132px;
}
#root .pqbox-category-content-wrapper > .category-btn {
  background-color: var(--grey100);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 8px;
}
#root .pqbox-category-content-wrapper > .category-btn > .category-title {
  font-size: 18px;
  font-weight: 700;
}
#root .pqbox-category-content-wrapper > .active-category {
  border: solid 1px var(--main);
  background-color: var(--violet100);
}
#root .pqbox-category-content-wrapper > .active-category > .category-title {
  color: var(--main);
}
#root .pqbox-category-content-wrapper > .active-category > svg > g > path {
  stroke: var(--main);
}