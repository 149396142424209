#root .user-nav-section {
  position: absolute;
  background-color: white;
  overflow: hidden;
  z-index: 10;
  left: -200vw;
  top: 49px;
  border: solid 1px var(--grey200);
  padding: 23px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  width: 360px;
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  z-index: 200;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
}
#root .user-nav-section .user-nav-cancel {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 30px;
  height: 30px;
}
#root .user-nav-section .user-nav-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
}
#root .user-nav-section .user-nav-btn-wrapper > a {
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .user-nav-section .user-nav-btn-wrapper > .project-btn {
  color: white;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
}
#root .user-nav-section .user-nav-profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .user-nav-section .user-nav-profile-wrapper .user-nav-profile-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .user-nav-section .user-nav-profile-wrapper .user-nav-profile-left > .profile-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--grey400);
}
#root .user-nav-section .user-nav-profile-wrapper .user-nav-profile-left > .profile-nickname {
  font-size: 16px;
}
#root .user-nav-section .user-nav-profile-wrapper > img {
  width: 24px;
}
#root .user-nav-section > .line {
  width: 100%;
  height: 1px;
  background: #dbdbdb;
}
#root .user-nav-section > button,
#root .user-nav-section a {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .open-nav {
  left: -80px;
  max-height: 600px;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
  #root .open-nav {
    left: -140px;
  }
}
@media screen and (max-width: 767px) {
  #root .user-nav-section {
    -webkit-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
    top: 0;
    width: 100%;
  }
  #root .user-nav-section .header-profile-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    position: relative;
  }
  #root .user-nav-section .header-profile-section .header-user-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    font-size: 14px;
  }
  #root .user-nav-section .header-profile-section .header-user-section .profile-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  #root .user-nav-section .header-profile-section .header-user-section:hover {
    opacity: 1;
  }
  #root .user-nav-section .header-profile-section .header-login-nav-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    font-size: 14px;
    font-weight: 400;
  }
  #root .user-nav-section .header-profile-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    position: relative;
  }
  #root .user-nav-section .header-profile-section .header-user-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    font-size: 14px;
  }
  #root .user-nav-section .header-profile-section .header-user-section .profile-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  #root .user-nav-section .header-profile-section .header-user-section:hover {
    opacity: 1;
  }
  #root .user-nav-section .header-profile-section .header-login-nav-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    font-size: 14px;
    font-weight: 400;
  }
  #root .open-nav {
    left: 0;
    max-height: 600px;
  }
}