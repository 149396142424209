#root .pqbox-management-page {
  padding: 130px 0;
  max-width: 1078px;
  margin: 0 auto;
  -webkit-animation: inside 0.5s ease-in-out;
          animation: inside 0.5s ease-in-out;
}
#root .pqbox-management-page .new-reward-btn {
  width: 150px;
  height: 42px;
  font-size: 16px;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, right top, from(#7F1CE1), color-stop(99.99%, #E74133), to(rgba(127, 28, 225, 0)));
  background: linear-gradient(90deg, #7F1CE1 0%, #E74133 99.99%, rgba(127, 28, 225, 0) 100%);
  color: white;
  border-radius: 10px;
}
#root .pqbox-management-page > .back-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 5px;
  width: 72px;
  height: 26px;
  background-color: #f5f6f8;
  margin-bottom: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .pqbox-management-page > .back-btn > img {
  width: 16px;
}
#root .pqbox-management-page > .back-btn > .text {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
#root .pqbox-management-page > .title-section-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .pqbox-management-page > .title-section-wrapper > .title {
  font-size: 30px;
  font-weight: 700;
}
#root .pqbox-management-page > .sub {
  font-size: 16px;
  margin-top: 8px;
  font-weight: 400;
}
#root .pqbox-management-page > .tag-section {
  display: grid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 157px;
  grid-template-columns: 232px 120px 80px 48px;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  margin-bottom: 8px;
}
#root .pqbox-management-page > .tag-section > .tag-content {
  text-align: center;
  font-size: 13px;
  color: var(--grey400);
  font-weight: 700;
}
#root .pqbox-management-page > .tag-section > .tag-content:nth-child(1) {
  text-align: left;
}
#root .pqbox-management-page .user-tag-section {
  grid-template-columns: 232px 108px 120px 80px 48px;
}
#root .pqbox-management-page .user-tag-section > .tag-content:nth-child(1), #root .pqbox-management-page .user-tag-section > .tag-content:nth-child(2) {
  text-align: left;
}
#root .pqbox-management-page .pqbox-list-content {
  display: grid;
  row-gap: 12px;
}

@media screen and (max-width: 1365px) {
  #root .pqbox-management-page {
    padding: 130px 20px;
  }
  #root .pqbox-management-page .pqbox-list-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 16px;
  }
}
@media screen and (max-width: 767px) {
  #root .pqbox-management-page .new-reward-btn {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    border-radius: 5px;
  }
  #root .pqbox-management-page .pqbox-list-content {
    grid-template-columns: 100%;
    gap: 24px;
  }
}