#root .pqbox-search-section {
  position: relative;
  margin: 16px 0 24px;
  width: 100%;
  padding: 16px;
  background-color: var(--grey100);
  display: grid;
  row-gap: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
}
#root .pqbox-search-section .search-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .pqbox-search-section .search-line .search-tag {
  width: 65px;
  font-size: 14px;
  font-weight: 700;
}
#root .pqbox-search-section .search-line .search-input {
  border: solid 1px var(--grey200);
  border-radius: 5px;
  width: 451px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 10px;
  height: 32px;
}
#root .pqbox-search-section > .search-btn {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 51px;
  height: 32px;
  background-color: var(--grey200);
  color: var(--grey500);
  font-size: 13px;
  border-radius: 5px;
  font-weight: 700;
}

@media screen and (max-width: 1365px) {
  #root .pqbox-search-section .search-line .search-input {
    width: 277px;
  }
}
@media screen and (max-width: 767px) {
  #root .pqbox-search-section .search-line .search-input {
    width: 110px;
  }
}